import React, { Component } from 'react'

import { Draggable } from 'react-beautiful-dnd'
import FieldCheckbox from '../field/checkbox';
import Toggle from '../field/toggle'
import FilterPane from './pane'
import Icon from '../icon'

import { defaultToggles } from '../../lib/defaults'

const ReportCard = ({
  index,
  id,
  type,
  label,
  pattern,
  display,
  onChange,
  careers,
  allowDescriptionToggle,
  allowCareersToggle,
}) => {

  return (
    <Draggable
      key={`${type}-${id}`}
      draggableId={`${type}-${id}`}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          className="report-card"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div
            className="report-card--move layout--pad1-b layout--pad1-v"
            {...provided.dragHandleProps}
          >
            <Icon icon="move" theme="primary" />
          </div>
          <FilterPane
            title={(
              <div className="page--header layout--pad1-h">
                <div>
                  <h3 className="section--header-title">{label}</h3>
                  <p>{pattern}</p>
                </div>
                <div className="page--header-action">
                  <Toggle
                    data={{
                      id: `${type}[${index}].display.all`,
                      style: 'large',
                      options: defaultToggles
                    }}
                    value={display.all}
                    onChange={onChange}
                  />
                </div>
              </div>
            )}
          >
          {(careers.list.length > 0) && (
            <div className="layout--pad2-b card--divider">
              {allowDescriptionToggle && (<div className="section--header">
                <h4 className="section--header-subtitle">Description</h4>
                <div className="section--header-action">
                  <Toggle
                    data={{
                      id: `${type}[${index}].display.desc`,
                      style: 'small',
                      options: defaultToggles
                    }}
                    value={display.desc}
                    onChange={onChange}
                  />
                </div>
              </div>)}
                <div className="section--header">
                  <h4 className="section--header-subtitle">Careers</h4>
                  {allowCareersToggle && (<Toggle
                    data={{
                      id: `${type}[${index}].display.careers`,
                      style: 'small',
                      options: defaultToggles
                    }}
                    value={display.careers}
                    onChange={onChange}
                  />)}
                </div>
                <ul className="report--careers-list">
                  {careers.list.map((career, key) => (
                    <li key={`${key}-${type}-career-${career.id}`}>
                      <FieldCheckbox
                        id={`${type}[${index}].careers[${key}].value`}
                        label={career.label}
                        checked={career.value}
                        onChange={onChange}
                      />
                    </li>
                  ))}
                </ul>
            </div>
          )}
          </FilterPane>
        </div>
      )}
    </Draggable>
  )
}

export default ReportCard
