/**
 *  API: scores
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request'

const route = id => `/api/clients/${id}/scores`;

export const scoresSave = (id, data) => request(route(id), {
  method: 'post',
  data
});

// Create a Context
const ScoresUpdateAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return {};
}

export const ScoresUpdateAPIProvider = ({ children }) => {
  const loader = useLoads('scores-update', scoresSave, {
    defer: true
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  return (
    <ScoresUpdateAPIContext.Provider value={ Object.assign({}, loader, {
      data
    })}>
      {children}
    </ScoresUpdateAPIContext.Provider>
  )
}

ScoresUpdateAPIProvider.propTypes = {
  children: PropTypes.element
}

export default ScoresUpdateAPIContext
