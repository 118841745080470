import React from 'react'

import AptitudeOption from './aptitudeOption'

const Aptitude = ({ data, value, onChange, style }) => {
  const options = Object.keys(data.options).map(option => ({
    label: data.options[option],
    value: option
  }));

  return (
    <div className={`aptitude aptitude--${style} ${!data.customizable && 'aptitude--static'}`}>
      {style !== 'large' && (<>
        <label htmlFor={data.id}>{data.label}</label>
        <span className="aptitude-score">{data.score}</span>
        </>
      )}
      {data.customizable && options.length > 0
        ? <div className="aptitude-options">
          <AptitudeOption
            id={data.id}
            value={value}
            style={style}
            options={options}
            onChange={onChange}
          />
        </div>
        : <div className="aptitude-options aptitude-options__is-empty">{data.value}</div>
      }
    </div>
  )
}

export default Aptitude
