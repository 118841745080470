/* eslint-disable max-statements */
/* eslint-disable max-params */
import React, { useContext, useState, useRef, useEffect } from 'react'
import Select from 'react-select';

import { Formik, Form, Field } from 'formik';
import { CSSTransition } from 'react-transition-group';

import MessagesContext, { MessagesProvider } from '../../../context/messages'
import SummaryPatternAPIContext, { SummaryPatternAPIProvider } from '../../../services/summaryPattern';
import { summaryPatternSave } from '../../../services/summaryPatternUpdate';
import SummaryReportAPIContext, { SummaryReportAPIProvider } from '../../../services/summaryReport';
import { summaryReportSave, summarySettingsSave } from '../../../services/summaryReportUpdate';

import { navigate } from 'gatsby';

import {
  DragDropContext,
  Droppable
} from 'react-beautiful-dnd'

import { get, flatMap, mergeMap } from '../../../utils/objects'

import Card from '../../card'
import AptitudeGroup from '../../field/aptitudeGroup';
import ReportCard from './../reportCard'
import FilterPane from './../pane'
import SummaryAbstract from './../summaryAbstract'
import Button from '../../button'
import Icon from '../../icon'
import ErrorBoundary from '../../ErrorBoundary'
import LoadingIndicator from '../../LoadingIndicator';
import Messages from '../../../components/messages';
import ExportReportButton from './../exportReportButton';
import SendReportButton from './../sendReportButton';
import { RegenerateReport, InitializeReport } from './ReportGenerate';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  const result = {};

  destClone.splice(droppableDestination.index, 0, removed);

  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const normalizePatterns = values => Object.keys(values).map(id => ({
  id,
  value: values[id]
}));

const normalizeReports = values => {
  return {
    ...values,
    primary: values.primary.map(normalizeReport),
    emphasis: values.emphasis.map(normalizeReport),
    discussion: values.discussion.map(normalizeReport),
    neutral: values.neutral.map(normalizeReport)
  };
}

const normalizeReport = values => ({
  ...values,
  // Convert careers to a simple array of selected IDs
  careers: {
    values: values.careers
      .filter(career => career.value)
      .map(career => career.id)
  }
});

const getId = result => result.id
  ? result.id
  : [result.label, result.pattern].join('-').replace(/\s/g, '-')

export const MessageCard = ({children}) => (
  <Card modifier="card layout--centered sticky-toolbar__container">
    <div className="message-overlay__content card--inner layout--centered">
      {children}
    </div>
  </Card>
);

/**
 *
 * Component
 */
const SummaryInner = ({ id }) => {
  // API contexts
  const summaryPatternAPI = useContext(SummaryPatternAPIContext);
  const summaryReportAPI = useContext(SummaryReportAPIContext);
  const { messages, setMessages } = useContext(MessagesContext)
  const aptitudes = summaryPatternAPI.getSummaryPattern();
  const reports = summaryReportAPI.data;
  const settings = summaryReportAPI.settings;

  const [
    disabled,
    setDisabled
  ] = useState(false);

  const [
    patternIsDirty,
    setPatternIsDirty
  ] = useState(false);

  const [
    reportIsDirty,
    setReportIsDirty
  ] = useState(false);

  const [
    settingsIsDirty,
    setSettingsIsDirty
  ] = useState(false);

  const patternFormRef = useRef(null);
  const reportFormRef = useRef(null);
  const settingsFormRef = useRef(null);

  const options = [
    { 'value': 'age', 'label': 'Auto' },
    { 'value': 'student', 'label': 'Student' },
    { 'value': 'adult', 'label': 'Adult' }
  ]

  if (!summaryReportAPI.isResolved) {
    return (<MessageCard><LoadingIndicator /></MessageCard>)
  }

  const canGenerateReport = Boolean(reports?.canGenerateReport);
  const noReport = Boolean(!reports?.id);

  if (noReport && canGenerateReport) {
    return (
      <InitializeReport
        id={id}
        setMessages={setMessages}
      />
    )
  }

  if (noReport && !canGenerateReport) {
    return (<MessageCard>
      <h2 className="message-overlay__heading">No Scores Recorded</h2>
      <p className="message-overlay__text">You must first input scores for this client before a report can be generated</p>
      <Button theme="secondary" onClick={() => {
        navigate(`/client/${id}/scores/`);
      }}>
        <span className="button__label">Input Scores</span>
      </Button>
      </MessageCard>)
  }


  if (reports.contentUpdateRequired == 1) {
    return (
      <RegenerateReport
        id={id}
        setMessages={setMessages}
      ></RegenerateReport>
    )
  }

  const getList = name => {
    const names = {
      'droppable-emphasis': 'emphasis',
      'droppable-discussion': 'discussion',
      'droppable-primary': 'primary',
      'droppable-neutral': 'neutral'
    }

    return names[name]
  }

  const onDragEnd = (result, values, setValues) => {

    const { type, source, destination } = result;

    if (!result.destination) {
      return;
    }

    const list = values[type]
    const srcIndex = source.index
    const desIndex = destination.index

    if (source.droppableId === destination.droppableId) {
      setValues(type, reorder(list, srcIndex, desIndex))
    } else {
      const newResult = move(
        values[getList(source.droppableId)],
        values[getList(destination.droppableId)],
        source,
        destination
      )

      Object.keys(newResult).forEach(key => {
        setValues(getList(key), newResult[key])
      })
    }
  };

  const onSave = () => {
    if (settingsIsDirty) {
      // save settings and then save pattern/report if dirty
      settingsFormRef.current.submitForm();
    }
    else {
      if (patternIsDirty) {
        // save just pattern
        patternFormRef.current.submitForm();
      } else if (reportIsDirty) {
        // save report
        reportFormRef.current.submitForm();
      }
    }
  }

  const getResults = items => items
    ? items.map((report, i) => ({
        id: report.id,
        label: report.label,
        pattern: report.pattern,
        display: report.display,
        careers: []
          .concat(
            get(report, 'careers.default', []),
            get(report, 'careers.optional', [])
          ).map(career => ({
            ...career,
            value: get(report, 'careers.values', []).indexOf(career.id) >= 0,
            test: get(report, 'careers.values', [])
          }))
      }))
    : [];

  // Simplify objects for Formik, just passing key -> value
  const values = flatMap(aptitudes, 'aptitudes');

  const getStatus = () =>  {
    if (patternIsDirty) {
      return "Aptitudes have unsaved changes";
    }

    if (reportIsDirty || settingsIsDirty) {
      return "Report has unsaved changes";
    }

    return "Saved";
  }

  const saveIsDisabled = !patternIsDirty && !reportIsDirty && !settingsIsDirty;

  const results = {
    primary: getResults(reports.primary),
    emphasis: getResults(reports.emphasis),
    discussion: getResults(reports.discussion),
    neutral: getResults(reports.neutral),
  }

  // List of careers keyed by ID to more easily fetch label.
  const careers = Object.assign(
    flatMap(results.primary.map(report => get(report, 'careers.defaults', [])), null, 'label'),
    flatMap(results.primary.map(report => get(report, 'careers.optional', [])), null, 'label'),
    flatMap(results.emphasis.map(report => get(report, 'careers.defaults', [])), null, 'label'),
    flatMap(results.emphasis.map(report => get(report, 'careers.optional', [])), null, 'label'),
    // JOC-339: Remove careers from Discussion and Neutral
    // flatMap(results.discussion.map(report => get(report, 'careers.defaults', [])), null, 'label'),
    // flatMap(results.discussion.map(report => get(report, 'careers.optional', [])), null, 'label'),
    // flatMap(results.neutral.map(report => get(report, 'careers.defaults', [])), null, 'label'),
    // flatMap(results.neutral.map(report => get(report, 'careers.optional', [])), null, 'label')
  );

  return (
    <Card modifier="card layout--sidebar-left sticky-toolbar__container">
      <div className="card--inner-tight card--row-alt card--divider-right">
        <h2>Customize</h2>
      </div>
      <div></div>
      <div className="card--row-alt card--divider card--divider-right">
        <div className="card--inner-tight">
          <SummaryAbstract title="Summary" items={reports.summary} />
        </div>
        <Formik
          ref={patternFormRef}
          initialValues={values}
          enableReinitialize={true}
          validate={values => {}}
          onSubmit={(values, { setSubmitting }) => {
            summaryPatternSave(id, normalizePatterns(values))
              .then(response => {
                if (!response.data.errors) {
                  summaryPatternAPI.load(id);
                  summaryReportAPI.load(id);
                }
                setSubmitting(false);
                setPatternIsDirty(false);
              })
              .catch(error => {
                let errors = [];
                if (error.response && error.response.errors) {
                  errors = errors.concat(Object.values(error.response.data.errors).map(err => err.title))
                } else if (error.status) {
                  errors.push(error.status);
                }

                setMessages(errors.map(err => ({
                  type: 'error',
                  text: err
                })));
                setSubmitting(false)
              })
          }}
        >
          {({ dirty, values, errors, touched, setFieldValue, handleChange, handleBlur, isSubmitting }) => {

            // Hoist the dirty value to state so we can access it outside this form.
            if (dirty !== patternIsDirty) {
              setPatternIsDirty(dirty)
            }

            return (
              <Form>
                <div className="card--inner-tight card--divider">
                  <h2>Aptitudes</h2>
                </div>

                <div className="card--inner-tight">
                  {aptitudes.map((group, i) => (
                    <AptitudeGroup
                      key={group.id}
                      label={group.label}
                      divider={group.divider}
                      aptitudes={group.aptitudes}
                      values={values}
                      onChange={handleChange}
                      style={group.style}
                    />
                  ))}
                </div>


              </Form>
            )
          }
        }
        </Formik>

        <div className="card--inner-tight card--divider">
          <h2>Settings</h2>
        </div>

        <div className="card--inner-tight">
          <Formik
            ref={settingsFormRef}
            initialValues={settings}
            enableReinitialize={true}
            validate={values => { }}
            onSubmit={(values, { setSubmitting }) => {
              summarySettingsSave(id, values)
                .then(response => {
                  if (patternIsDirty) {
                    patternFormRef.current.submitForm();
                  }
                  if (reportIsDirty) {
                    reportFormRef.current.submitForm();
                  }
                  if (!response.data.errors) {
                    summaryReportAPI.settingsLoader.load(id);
                  }
                  setSettingsIsDirty(false);
                  setSubmitting(false);
                })
                .catch(error => {
                  let errors = [];
                  console.log(error);
                  if (error.response && error.response.errors) {
                    errors = errors.concat(Object.values(error.response.errors).map(err => err.title))
                  } else if (error.status) {
                    errors.push(error.status);
                  }

                  setMessages(errors.map(err => ({
                    type: 'error',
                    text: err
                  })));
                  setSubmitting(false)
                })
            }}
          >
            {({ dirty, values, errors, touched, setFieldValue, handleChange, handleBlur, isSubmitting }) => {

              // Hoist the dirty value to state so we can access it outside this form.
              if (dirty !== settingsIsDirty) {
                setSettingsIsDirty(dirty)
              }

              return (
                <Form>
                  <div className="layout--fields-2col field-select">
                    <label>What Now</label>
                    <Select
                      value={options.find(option => {
                        return values.boilerplate && option.value === values.boilerplate.what_now
                      })}
                      name="boilerplate.what_now"
                      onChange={value => {setFieldValue(`boilerplate.what_now`, value.value)}}
                      options={options}></Select>
                  </div>
                  <div className="field-text--description">
                    Select "Auto" to show either student or adult based on client's age. To bypass age calculation select either adult or student.
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
      <div className="card--divider card--root">
        <div className="layout--pad2-v">
          {messages.length > 0 && (
            <div className="card--inner-tight">
              <Messages />
            </div>
          )}
          <Formik
            ref={reportFormRef}
            initialValues={results}
            enableReinitialize={true}
            validate={values => {}}
            onSubmit={(values, { setSubmitting }) => {
              summaryReportSave(id, normalizeReports(values))
              .then(response => {
                  if (!response.data.errors) {
                    summaryReportAPI.load(id);
                  }
                  setReportIsDirty(false);
                  setSubmitting(false);
                })
                .catch(error => {
                  let errors = [];
                  console.log(error);
                  if (error.response && error.response.errors) {
                    errors = errors.concat(Object.values(error.response.data.errors).map(err => err.title))
                  } else if (error.status) {
                    errors.push(error.status);
                  }

                  setMessages(errors.map(err => ({
                    type: 'error',
                    text: err
                  })));
                  setSubmitting(false)
                })
            }}
          >
            {({ dirty, values, errors, touched, setFieldValue, handleChange, handleBlur, isSubmitting }) => {

              // Hoist the dirty value to state so we can access it outside this form.
              if (dirty !== reportIsDirty) {
                setReportIsDirty(dirty)
              }

              return (
              <Form>
                <DragDropContext
                  onDragEnd={newResults => {
                    onDragEnd(newResults, values, setFieldValue)
                  }}
                >
                  {values.primary.length > 0 &&
                  <FilterPane
                    title={<h2 className="report-card--title">Primary</h2>}
                    animate={false}
                  >
                    <Droppable droppableId="droppable-primary" type="primary">
                      {(provided, snapshot) => (
                        <ErrorBoundary>
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {values.primary.map((result, i) => (
                            <ReportCard
                              key={`primary-${getId(result)}`}
                              id={getId(result)}
                              index={i}
                              type="primary"
                              label={result.label}
                              pattern={result.pattern}
                              display={{
                                all: values.primary[i].display.all,
                                desc: values.primary[i].display.desc,
                                careers: values.primary[i].display.careers
                              }}
                              careers={{
                                list: result.careers,
                                labels: careers,
                                status: values.primary[i].careers
                              }}
                              onChange={handleChange}
                              allowDescriptionToggle={true}
                              allowCareersToggle={true}
                            />
                          ))}
                          {provided.placeholder}
                        </div>
                        </ErrorBoundary>
                      )}
                    </Droppable>
                  </FilterPane>}
                  <hr />
                  {values.emphasis.length > 0 &&
                  <FilterPane
                    title={<h2 className="report-card--title">Emphasis</h2>}
                    animate={false}
                  >
                    <Droppable droppableId="droppable-emphasis" type="emphasis">
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {values.emphasis.map((result, i) => (
                          <ReportCard
                            key={`emphasis-${getId(result)}`}
                            id={getId(result)}
                            index={i}
                            type="emphasis"
                            label={result.label}
                            pattern={result.pattern}
                            display={{
                              all: values.emphasis[i].display.all,
                              desc: values.emphasis[i].display.desc,
                              careers: values.emphasis[i].display.careers
                            }}
                            careers={{
                              list: result.careers,
                              labels: careers,
                              status: values.emphasis[i].careers
                            }}
                            onChange={handleChange}
                            allowDescriptionToggle={false}
                            allowCareersToggle={false}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                    </Droppable>
                  </FilterPane>}
                  {values.discussion.length > 0 &&
                  <FilterPane
                    title={<h2 className="report-card--title">Discussion</h2>}
                    animate={false}
                  >
                    <Droppable droppableId="droppable-discussion" type="discussion">
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {values.discussion.map((result, i) => (
                            <ReportCard
                              key={`discussion-${getId(result)}`}
                              id={getId(result)}
                              index={i}
                              type="discussion"
                              label={result.label}
                              pattern={result.pattern}
                              display={{
                                all: values.discussion[i].display.all,
                                desc: values.discussion[i].display.desc,
                                careers: values.discussion[i].display.careers
                              }}
                              careers={{
                                list: result.careers,
                                labels: careers,
                                status: values.discussion[i].careers
                              }}
                              onChange={handleChange}
                              allowDescriptionToggle={false}
                              allowCareersToggle={false}
                            />
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </FilterPane>}
                  {values.neutral.length > 0 &&
                  <FilterPane
                    title={<h2 className="report-card--title">Neutral</h2>}
                    animate={false}
                  >
                    <Droppable droppableId="droppable-neutral" type="neutral">
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {values.neutral.map((result, i) => (
                            <ReportCard
                              key={`neutral-${getId(result)}`}
                              id={getId(result)}
                              index={i}
                              type="neutral"
                              label={result.label}
                              pattern={result.pattern}
                              display={{
                                all: values.neutral[i].display.all,
                                desc: values.neutral[i].display.desc,
                                careers: values.neutral[i].display.careers
                              }}
                              careers={{
                                list: result.careers,
                                labels: careers,
                                status: values.neutral[i].careers
                              }}
                              onChange={handleChange}
                              allowDescriptionToggle={false}
                              allowCareersToggle={false}
                            />
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </FilterPane>}
                </DragDropContext>
              </Form>
            )}
          }
          </Formik>
          <CSSTransition
            in={patternIsDirty}
            timeout={200}
            classNames="message-overlay-">
            <div className="message-overlay__container">
              <div className="message-overlay__content card--inner layout--centered">
                <h2 className="message-overlay__heading">Save Required</h2>
                <p className="message-overlay__text">You have made changes to the report settings that affects this content. You must save your changes to continue editing report content.</p>
                <Button theme="secondary" onClick={() => {
                  patternFormRef.current.resetForm();
                }}>
                  <span className="button__label">Cancel</span>
                </Button>
              </div>
            </div>
          </CSSTransition>
        </div>
      </div>
      <div className="card--inner-tight layout--buttons-weight-right sticky-toolbar">
        <div className="sticky-toolbar__primary">
          <Button
            theme="primary"
            onClick={onSave}
            disabled={saveIsDisabled}
            >
            <span className="button__label">Save</span>
          </Button>
          <p className="sticky-toolbar__status">{getStatus()}</p>
        </div>
        <Button theme="secondary" href={`/report-preview/${id}`} target="_blank" disabled={!saveIsDisabled}>
          <span className="button__label">Preview</span>
          <Icon theme="secondary" icon="search" />
        </Button>
        <ExportReportButton disabled={!saveIsDisabled} />
        <SendReportButton disabled={!saveIsDisabled} id={id} />
      </div>
    </Card>
  )
}

const Summary = props => (
  <SummaryPatternAPIProvider {...props}>
    <SummaryReportAPIProvider {...props}>
      <SummaryInner {...props} />
    </SummaryReportAPIProvider>
  </SummaryPatternAPIProvider>
);

export default Summary
