import React from 'react'
import { parseId } from '../../utils/path';

import Client from '../../components/client';
import ClientError from '../../components/client/error';
import ClientLayout from '../../components/client/ClientLayout';
import DashboardLayout from '../../components/dashboard/DashboardLayout';
import Page from '../../components/page';

const ClientPage = ({ location }) => {
  const id = parseId(location.pathname);

  return (
    <Page authenticated={true}>
      <DashboardLayout>
        <ClientLayout id={id} >
          <>
            {id
              ? <Client id={id} />
              : <ClientError />
            }
          </>
        </ClientLayout>
      </DashboardLayout>
    </Page>
  )
}

export default ClientPage
