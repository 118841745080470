import React from 'react'

const AptitudeOption = ({ id, style, value, options, onChange }) => (
  options.map((option, i) => (
    <label
      key={i}
      className={`aptitude-option--label ${value === option.value ? 'aptitude-option--label__is-selected' : ''}`}
    >
      <span>{style === 'large'
        ? option.label
        : option.label.substring(0, 1)}
      </span>
      <input
        type="radio"
        name={id}
        value={option.value}
        checked={value === option.value}
        onChange={onChange}
      />
    </label>
  ))
)

export default AptitudeOption
