/**
 *  API: orderAdd
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useLoads } from 'react-loads'
import { request } from '../lib/request'

const route = clientId => `/api/clients/${clientId}/orders/`

export const orderAdd = (clientId, data) => request(route(clientId), {
  method: 'post',
  data
});

// Create a Context
const OrderAddAPIContext = React.createContext()

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data
  }

  return {}
}

export const OrderAddAPIProvider = ({ children }) => {
  const loader = useLoads('order-add', orderAdd, {
    defer: true,
  })

  // Raw response data
  const data = getDataFromResponse(loader.response)

  return (
    <OrderAddAPIContext.Provider value={Object.assign({}, loader, { data })}>
      {children}
    </OrderAddAPIContext.Provider>
  )
}

OrderAddAPIProvider.propTypes = {
  children: PropTypes.element,
}

export default OrderAddAPIContext
