/* eslint-disable no-magic-numbers */
import React, { useContext, useEffect } from 'react'
import * as Yup from 'yup';
import Button from '../button'
import Icon from '../icon'
import ReportSendAPIContext, { ReportSendAPIProvider } from '../../services/reportSend';
import { Formik, Form, Field, FieldArray, getIn } from 'formik'
import FieldText from '../field/formikText'
import LoadingIndicator from '../LoadingIndicator';


const FormSchema = Yup.object().shape({
  emails: Yup.array().of(Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('Please enter an Email')
  }))
});

const normalizeRequest = (values, id) => ({
  client_id: id,
  regenerate: '0',
  emails: values.emails.map(email => email.email)
});

const ErrorMessage = ({ name }) => (
  <Field
    name={name}
  >
    {({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);

      return ((touch && error)
        ? (<div className="field__error">{error}</div>)
        : null
      );
    }}
  </Field>
);

const ReportSendFormInner = ({ id, onSuccess = () => { }, onCancel = () => { }, emails = [] }) => {
  const reportSendAPI = useContext(ReportSendAPIContext);

  const {
    load,
    isPending,
    isResolved,
    data = {}
  } = reportSendAPI;
  const { message, errors } = data;

  useEffect(() => {
    if (isResolved) {
      onSuccess();
    }
  }, [isResolved])

  const onSubmit = (values) => {
    load(id, normalizeRequest(values, id));
  };

  if (isResolved) {
    return (
    <>
      {message && (<p>{message}</p>)}
      {errors && errors.map(error => (<p>{error.detail}</p>))}
    </>
    );
  }

  if (isPending) {
    return (<LoadingIndicator text={'Sending'} />)
  }

  return (
    <>
      <p>Enter the email addresses you would like to send to this report to.</p>
      <Formik
        initialValues={{
          emails: emails.map(email => ({email}))
        }}
        enableReinitialize={true}
        validate={values => {}}
        validationSchema={FormSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, setFieldValue, touched, isSubmitting }) => (
          <Form>
            <FieldArray name="emails" render={arrayHelpers => (
              <>
                {values.emails.map((email, index) => (
                  <div className="field field--inline" key={index}>
                    <div className="field__inner">
                      <Field type="email"
                        id={`emails.${index}.email`}
                        name={`emails.${index}.email`}
                        >
                        {({ field }) => (
                          <FieldText
                            {...field}
                            onChange={(event) => { setFieldValue(event.target.name, event.target.value.trim()); }}
                            label={`Email Address ${index + 1}`} required />
                          )}
                      </Field>
                      <ErrorMessage name={`emails.${index}.email`} />
                    </div>
                    <div className="field__inner">
                      <Button type="button" theme="tertiary" onClick={() => arrayHelpers.remove(index)} disabled={values.emails.length === 1 && index === 0}><Icon icon="close" theme="secondary" />Remove</Button>
                    </div>
                  </div>
                ))}

                <Button theme="secondary" onClick={() => arrayHelpers.push(Object.assign({}, {email: ""}))} type="button">
                  <Icon icon="plus" /> Add Email
                </Button>
              </>
            )}
            />
            <div className="button-group">
              <Button theme="primary" disabled={isPending}>
                <span className="button__label" disabled={isPending}>Send</span>
              </Button>
              <Button theme="secondary" disabled={isPending} onClick={onCancel} type="button">
                <span className="button__label" disabled={isPending}>Cancel</span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>

    </>
  )
}

const ReportSendForm = props => {

  return (
    <ReportSendAPIProvider>
      <ReportSendFormInner {...props} />
    </ReportSendAPIProvider>
  );
}

export default ReportSendForm;
