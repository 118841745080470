import React, { useContext } from 'react'

import Button from '../button'
import Icon from '../icon'
import DialogContext from '../../context/dialog';
import ClientInfoSendForm from './sendClientInfoForm';
import ClientAPIContext from '../../services/client';

const ClientInfoSendButton = ({ id, disabled }) => {
  const dialog = useContext(DialogContext);
  const clientAPI = useContext(ClientAPIContext);

  const heading = 'Send Client Info Form';

  const onClick = () => {
    dialog.openWith({
      heading,
      content: <ClientInfoSendForm onCancel={dialog.close} id={id} email={clientAPI.getEmail()} />,
    });
  }

  return (
    <Button theme="secondary" onClick={onClick} disabled={disabled}>
      <Icon theme="secondary" icon="envelope" modifier="next" />
      <span className="button__label">Send Client Info Form</span>
    </Button>
  )
}

export default ClientInfoSendButton
