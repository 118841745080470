/* eslint-disable array-element-newline */
/* eslint-disable no-magic-numbers */
import React, { useContext } from 'react'

import Appointment from './appointment'

import ClientContext from '../../services/client';

const nextAppointment = rows => {
  const ids = rows.map(row => appointments[i].id)

  // Return the first missing appointment.
  for (let i = 1; i < 4; i++) {
    if (ids.indexOf(i) === -1) {
      return i;
    }
  }
}

const Appointments = ({ add, reschedule }) => {
  const clientAPI = useContext(ClientContext);
  const appointments = clientAPI.getAppointments();
  const rows = appointments.reduce((acc, current) => {
    acc[current.appointment] = current;
    return acc;
  }, {
    1: null,
    2: null,
    3: null,
    4: null
  });

  return (
    <div className="dg dg--center-ruled">
      {Object.keys(rows).map(number => (
        <Appointment
          key={number}
          appointment={rows[number]}
          number={number}
          reschedule={reschedule}
          add={add}
          refresh={clientAPI.load}
        />
      ))}
  </div>
)};

export default Appointments
