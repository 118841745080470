import React, { useContext } from 'react';
import Select from 'react-select';
import { Formik, Form, Field, FieldArray } from 'formik';

import * as Yup from 'yup';

import FieldTextArea from '../field/textarea';
import FieldText from '../field/formikText';

import ClientUpdateAPIContext, { ClientUpdateAPIProvider } from '../../services/clientUpdate';
import ClientInfoFinalAPIContext, { ClientInfoFinalAPIProvider } from '../../services/clientInfoFinal';
import ClientInfoFinalUpdateAPIContext, { ClientInfoFinalUpdateAPIProvider } from '../../services/clientInfoFinalUpdate';
import ErrorMessage from '../../components/ErrorMessage'
import Messages from '../../components/messages';
import MessagesContext from '../../context/messages';
import Card from '../card';
import Button from '../button';
import Icon from '../icon';
import LoadingIndicator from '../LoadingIndicator';

import { defaults } from '../../lib/defaults';
import { removeAccessToken } from '../../lib/oauth';
import Tooltip from '../client/tooltip';

const FormSchema = Yup.object().shape({
  referredEmail: Yup.string()
    .email('Invalid email')
});

const reqsAdditionalFields = () => (
  defaults.referred.filter((value) => (value.collectContactInfo))
)

const requiresContactInfo = (fieldValue) => {
  return reqsAdditionalFields().map(value => value.value).includes(fieldValue)
}

const FinalInner = ({
  id,
  heading = 'Final',
  onSuccess = () => {},
  onCancel
}) => {
  const api = useContext(ClientInfoFinalAPIContext);
  const { isResolved, data } = api;
  const clientFinalUpdateAPI = useContext(ClientInfoFinalUpdateAPIContext);
  const clientUpdateAPI = useContext(ClientUpdateAPIContext);
  const { messages, setMessages } = useContext(MessagesContext);
  const infoMessage = () => (
    <div className='text-highlight'>
    <p>
      <b>That’s great!</b>  We depend on word of mouth for most of our business.  We like to show appreciation to our biggest referrers.  Thank you for helping us do so by providing the following information:
    </p>
    </div>
  )


  if (!isResolved) {
    return (<Card>
      <div className="card--inner">
        <LoadingIndicator />
      </div>
    </Card>);
  }

  const client = api.data;

  return (
    <div className="container-panel">
      <Card>
        <Formik
          initialValues={{
            referred: data.referred,
            referredAd: data.referredAd,
            referredName: data.referredName,
            referredEmail: data.referredEmail,
            referredOther: data.referredOther,
            clientNotes: data.clientNotes
          }}
          enableReinitialize={true}
          validate={values => {}}
          validationSchema={FormSchema}
          onSubmit={(values, { setSubmitting }) => {
            clientFinalUpdateAPI.load(id, values)
              .then(response =>

                /*
                 * Access token will be revoked by the server on
                 * a successful client update.
                 */
                removeAccessToken())
              .then(() =>
                // Mark info as complete.
                clientUpdateAPI.load(id, {
                  infoComplete: 1
                }))
              .then(response => {

                /*
                 * Access token will be revoked by the server on
                 * a successful client update.
                 */
                removeAccessToken();

                setMessages([
                  {
                    type: 'status',
                    text: 'Feedback information saved.'
                  }
                ]);
                setSubmitting(false);
                onSuccess();
              })
              .catch(error => {
                let errors = [];

                console.log(error);
                if (error.response && error.response.errors) {
                  errors = errors.concat(Object.values(error.response.data.errors).map(err => err.title));
                } else if (error.status) {
                  errors.push(error.status);
                }

                setMessages(errors.map(err => ({
                  type: 'error',
                  text: err
                })));
                setSubmitting(false);
              });
          }}
        >
          {({ values, errors, touched, setFieldValue, handleChange, isSubmitting }) => (
            <Form>
              <div className="card--inner">
                <Messages messages={messages} />
                <h1>A few last Questions...</h1>
              </div>


              <div className="card--inner-tight">

                <label className='field-text--label'>How did you hear about JOCRF?</label>
                <Select
                  id="referred"
                  value={defaults.referred.find(option => {
                    return values.referred && option.value === values.referred
                  })}
                  name="referred"
                  onChange={value => {setFieldValue(`referred`, value.value)}}
                  options={defaults.referred.map(referred => ({
                    label: referred.label,
                    value: referred.value
                  }))}
                />
              </div>

              <div className={
                ' card--inner-tight card--conditional card--highlight card--conditional--' +
                // Only show if it's one of the people options to add contact info for
                (requiresContactInfo(values.referred) ? 'show' : 'hide')
              }>
                {infoMessage()}
                <div className="layout--even-2col">
                  <div>
                    <Field
                      type="text"
                      name="referredName"
                    >
                      {({ field }) => (
                        <FieldText {...field} label="Referral name" />
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field
                      type="email"
                      name="referredEmail"
                    >
                      {({ field }) => (
                        <FieldText {...field} label="Referral email" />
                      )}
                    </Field>
                    <ErrorMessage name="referredEmail" />
                  </div>
                </div>
              </div>

              {/* Only show if "ad" chosen */}
              <div className={
                'card--inner-tight card--highlight card--conditional card--conditional--' + (values.referred == "ad" ? 'show' : 'hide')}>
                <Field
                  type="text"
                  name="referredAd"
                >
                  {({ field }) => (
                    <FieldText {...field} label="Please specify where you saw an ad" />
                  )}
                </Field>
              </div>

              {/* Only show if "other" chosen */}
              {values.referred == "other" &&
                <div className="card--inner-tight">
                  <Field
                    type="text"
                    name="referredOther"
                  >
                    {({ field }) => (
                      <FieldText {...field} label="Please specify where else" />
                    )}
                  </Field>
                </div>
              }


              <div className="card--inner-tight">
                <FieldTextArea
                  id="clientNotes"
                  label="Are there any further details you’d like to share about how you found us?"
                  value={values.clientNotes}
                  onChange={handleChange}
                />
              </div>
              <div className="card--inner card--divider layout-add-submit">
                <div className="button-group">
                  <Button theme="primary" type="submit" disabled={isSubmitting}>
                    <span className="button__label">Complete</span>
                    <Icon icon="forward" theme="primary" />
                  </Button>
                  {onCancel && (
                    <Button theme="secondary" type="button" disabled={isSubmitting} onClick={onCancel}>
                      <span className="button__label" >Cancel</span>
                      <Icon icon="close" theme="secondary" />
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

const Final = props => (
  <ClientInfoFinalAPIProvider {...props}>
    <ClientInfoFinalUpdateAPIProvider {...props}>
      <ClientUpdateAPIProvider {...props}>
        <FinalInner {...props} />
      </ClientUpdateAPIProvider>
    </ClientInfoFinalUpdateAPIProvider>
  </ClientInfoFinalAPIProvider>
);

export default Final;
