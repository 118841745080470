import subWeeks from 'date-fns/sub_weeks'
import addWeeks from 'date-fns/add_weeks'
import eachDay from 'date-fns/each_day'
import getHours from 'date-fns/get_hours'
import setHours from 'date-fns/set_hours'
import addHours from 'date-fns/add_hours'
import setMinutes from 'date-fns/set_minutes'
import setSeconds from 'date-fns/set_seconds'
import startOfWeek from 'date-fns/start_of_week'
import endOfWeek from 'date-fns/end_of_week'
import format from 'date-fns/format'

import * as faker from 'faker'

import { defaultDuration } from '../lib/defaults'

const setAM = date => setSeconds(setMinutes(setHours(date, 9), 30), 0)
const setPM = date => setSeconds(setMinutes(setHours(date, 14), 30), 0)

const now = new Date()
const monday = startOfWeek(now, {weekStartsOn: 1})
// const friday = subDays(endOfWeek(now), 1)
// const saturday = endOfWeek(now)
const sunday = endOfWeek(now, {weekStartsOn: 1})

const week = eachDay(monday, sunday)
const weekLast = week.map(day => subWeeks(day, 1))
const weekNext = week.map(day => addWeeks(day, 1))

const weekAM = week.map(date => setAM(date))
const weekLastAM = weekLast.map(date => setAM(date))
const weekNextAM = weekNext.map(date => setAM(date))

const weekPM = week.map(date => setPM(date))
const weekLastPM = weekLast.map(date => setPM(date))
const weekNextPM = weekNext.map(date => setPM(date))


const times = [
  ...weekAM,
  ...weekPM,
  ...weekLastAM,
  ...weekLastPM,
  ...weekNextAM,
  ...weekNextPM,
]

const randomHigh = limit => Math.ceil(Math.random() * Math.ceil(limit))
const randomLow = limit => Math.floor(Math.random() * Math.floor(limit))

const pickAppointment = () => randomHigh(3)
const pickDate = () => times[randomLow(times.length)]

const pickEducation = () => {
  const education = [
    'High School',
    'Some College',
    'College',
    'Graduate School'
  ]

  return education[randomLow(education.length)]
}

const pickSchool = () => {
  const levels = [
    'University',
    'College',
    'School'
  ]

  return levels[randomLow(levels.length)]
}

const pickName = () => {
  const names = [
    'Joya',
    'Fredericka',
    'Shizue',
    'Gia',
    'Saundra',
    'Katerine',
    'Gaston',
    'Mindi',
    'Mitsuko',
    'Monnie'
  ]

  return names[randomLow(names.length)]
}

const pickReason = () => {
  const notes = [
    'out',
    'doctor appointment',
    'picking up kids',
    'on vacation',
    'meeting movers',
    'called in sick',
    'meeting with aten'
  ]

  return notes[randomLow(notes.length)]
}

const pickTime = date => {
  const times = {
    am: [
      'in the morning',
      'all morning',
      'morning',
      'full day',
      'all day'
    ],
    pm: [
      'in the afternoon',
      'all afternoon',
      'afternoon',
      'after lunch'
    ]
  }

  const ampm = getSlot(date)
  const reasons = times[ampm]

  return reasons[randomLow(reasons.length)]
}

const getSlot = date => getHours(date) < 12 ? 'am' : 'pm'

const generateNote = (num, date) => {
  let note = []

  for (let i = 0; i < num; i++) {
    note.push(`${pickName()} ${pickReason()} ${pickTime(date)}\n`)
  }

  return note.join('')
}
const generateLogs = (num, availability) => {

  let logs = []

  for (let i = 1; i < num; i++) {
    const strength = randomHigh(availability)
    const date = pickDate()

    logs.push({
      note: generateNote(strength, date),
      availability: availability - strength,
      date
    })
  }

  return logs
}

const generateAppointment = appointment => {
  const start = pickDate()

  return {
    id: faker.random.number({min:1000, max:9999}),
    appointment,
    date: {
      start,
      end: addHours(start, defaultDuration(appointment))
    },
    location: {
      id: 1,
      name: "Denver"
    },
    complete: faker.random.boolean()
  }
}

export const generateAppointments = () => {
  const appointments = []

  for (let i=1; i<4; i++) {
    if (faker.random.boolean()) {
      appointments.push(generateAppointment(i))
    }
  }

  return appointments
}

export const names = [
  {
    name: 'Annette Hackett',
    folder: '482256-83'
  },
  {
    name: 'Debi Sharples',
    folder: '475258-46'
  },
  {
    name: 'Ilene Ashley',
    folder: '744475-64'
  },
  {
    name: 'Andreas Adam',
    folder: '726631-83'
  },
  {
    name: 'Kareem Long',
    folder: '405613-98'
  },
  {
    name: 'Roberto Jones',
    folder: '216571-44'
  },
  {
    name: 'Naomi Mulligan',
    folder: '811947-98'
  },
  {
    name: 'Charles Cooper',
    folder: '978576-06'
  },
  {
    name: 'Melodie Beech',
    folder: '388124-68'
  },
  {
    name: 'Eda Oliver',
    folder: '681039-20'
  },
  {
    name: 'Emilee Beaumont',
    folder: '711820-53'
  },
  {
    name: 'Hazel Tierney',
    folder: '822718-30'
  },
  {
    name: 'Nona Cunningham',
    folder: '872023-60'
  },
  {
    name: 'Basilia Stuart',
    folder: '375240-35'
  },
  {
    name: 'Bethann Marsden',
    folder: '221075-33'
  },
  {
    name: 'Viola Hurst',
    folder: '780360-59'
  },
  {
    name: 'Marybelle Barclay',
    folder: '362600-79'
  },
  {
    name: 'Bev Hunter',
    folder: '565577-36'
  },
  {
    name: 'Ignacio Brewer',
    folder: '550275-19'
  },
  {
    name: 'Guillermo Mcrae',
    folder: '880671-81'
  },
  {
    name: 'Greg O donnell',
    folder: '996119-37'
  },
  {
    name: 'Tamala Edge',
    folder: '363263-60'
  },
  {
    name: 'Flo Braithwaite',
    folder: '545777-47'
  },
  {
    name: 'Bobette Burnett',
    folder: '313739-05'
  },
  {
    name: 'Velva Mcintosh',
    folder: '664216-94'
  },
  {
    name: 'Nery Kerr',
    folder: '620080-21'
  },
  {
    name: 'Lynell Stanton',
    folder: '698708-08'
  },
  {
    name: 'Shawn Allan',
    folder: '368181-23'
  },
  {
    name: 'Deedra Hale',
    folder: '693669-59'
  },
  {
    name: 'Sachiko Brandrick',
    folder: '290263-73'
  },
  {
    name: 'Rosendo Lynch',
    folder: '486057-75'
  },
  {
    name: 'Noel Nicholls',
    folder: '583783-02'
  },
  {
    name: 'William Wilde',
    folder: '823861-67'
  },
  {
    name: 'Jennette Blanchard',
    folder: '270865-13'
  },
  {
    name: 'Frank Fielding',
    folder: '408312-56'
  },
  {
    name: 'Samuel Crowther',
    folder: '765314-96'
  },
  {
    name: 'Berna Kouma',
    folder: '275821-31'
  },
  {
    name: 'Jarrod Morton',
    folder: '562916-13'
  },
  {
    name: 'Jake Derrick',
    folder: '695902-07'
  },
  {
    name: 'Eulah Webber',
    folder: '748711-28'
  },
  {
    name: 'Damian Lewis',
    folder: '151603-73'
  },
  {
    name: 'Chance Marriott',
    folder: '008916-69'
  },
  {
    name: 'Lorine Simons',
    folder: '141144-46'
  },
  {
    name: 'Blondell Levy',
    folder: '061476-19'
  },
  {
    name: 'Rueben Watt',
    folder: '904297-36'
  },
  {
    name: 'Shaquana Welch',
    folder: '737637-57'
  },
  {
    name: 'Melanie Bowen',
    folder: '687588-94'
  },
  {
    name: 'Chong Moseley',
    folder: '465731-78'
  },
  {
    name: 'Leontine Santos',
    folder: '229094-97'
  },
  {
    name: 'Elmira Firth',
    folder: '019619-22'
  }
]

export const data = {

  // appointments: names.map(appointment => {
  //   appointment.id = faker.random.number({min:1000, max:9999})
  //   appointment.date = pickDate()
  //   appointment.appointment = pickAppointment()

  //   return appointment
  // }),
  appointments: names.map(client => {
    const appointment = generateAppointment(pickAppointment())

    return Object.assign(appointment, { client })
  }),
  logs: generateLogs(10, 4)
}

export const appointments = generateAppointments()

const dob = faker.date.between('1975-01-01', '2000-01-05')

export const info = {
  contact: {
    name: {
      label: 'Name',
      value: faker.name.findName()
    },
    address: {
      label: 'Address',
      value: `${faker.address.streetAddress()} ${faker.address.city()}, ${faker.address.state()} ${faker.address.zipCode()}`
    },
    dob: {
      label: 'Birthdate',
      value: format(dob, 'MM/DD/YYYY')
    },
    email: {
      label: 'Email',
      value: faker.internet.email()
    },
    phone: {
      label: 'Phone',
      value: faker.phone.phoneNumber()
    },
    preferred: {
      label: 'Preferred Contact',
      value: 'email'
    }
  },
  education: {
    completed: {
      label: 'Education Completed',
      value: `${pickEducation()}`
    },
    school: {
      label: 'Present or Last School',
      value: `${faker.address.county()} ${pickSchool()}`
    },
    dates: {
      label: 'Dates Attended',
      value: `From ${format(addWeeks(dob, 52 * 18), 'YYYY')} to ${format(addWeeks(dob, 52 * 22), 'YYYY')}`
    },
    degree: {
      label: 'Degree Earned',
      value: faker.company.catchPhrase()
    },
    plans: {
      label: 'Further Education Plans',
      value: faker.random.boolean()
        ? 'Yes'
        : 'No'
    }
  },
  family: [
    {
      label: 'Parent1',
      value: {
        name: {
          label: 'Name',
          value: faker.name.findName()
        },
        occupation: {
          label: 'Occupation',
          value: faker.name.jobTitle()
        },
        work: {
          label: 'Nature of Work',
          value: faker.name.jobType()
        },
        education: {
          label: 'Education Completed',
          value: pickEducation()
        }
      }
    },
    {
      label: 'Parent2',
      value: {
        name: {
          label: 'Name',
          value: faker.name.findName()
        },
        occupation: {
          label: 'Occupation',
          value: faker.name.jobTitle()
        },
        work: {
          label: 'Nature of Work',
          value: faker.name.jobType()
        },
        education: {
          label: 'Education Completed',
          value: `${pickEducation()}`
        }
      }
    }
  ]
}
