import React, { useContext } from 'react'

import Complete from '../../../appointment/complete'
import AppointmentError from '../../../appointment/error'
import ProgressBar from '../../../progressBar'
import PageTitle from '../../pageTitle';
import MessagesContext from '../../../../context/messages';
import Messages from '../../../messages';

const AddComplete = ({ client }) => {
  const { messages } = useContext(MessagesContext);

  return (
    <>
      <ProgressBar currentStep={'6'} client={client}></ProgressBar>
      <PageTitle />
      <Messages messages={messages} />
      {client
        ? <Complete client={client} next={`/client/${client}`} />
        : <AppointmentError />
      }
    </>
  )
}

export default AddComplete
