import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types';
import Select from './select';
import ProductsAPIContext from '../../services/products';


const ProductSelect = props => {
  const productsAPI = useContext(ProductsAPIContext);
  const { isResolved, isIdle, options } = productsAPI;

  // Refetch the client on each page load.
  useEffect(() => {
    // Load if we
    if (isIdle) {
      productsAPI.load();
    }
  }, [window.location.pathname]);

  if (!isResolved) {
    return (<span>Loading</span>);
  }

  return (
    <Select
      {...props}
      nonOption="Select a Product"
      options={options}
      className="field-select--tight"
    >
    </Select>
  );
};

ProductSelect.propTypes = {};

ProductSelect.defaultProps = {
  options: []
};

export default ProductSelect;
