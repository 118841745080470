import React from 'react'
import ComplexTable from './ComplexTable'


const LabelTable = ({ label = '', rows, qa }) => (
  <div data-qa={qa} className="dg--label-table">
    {label && <h4 className="dg--label-table--label">{label}</h4>}
    <div className="dg">
      {Object.keys(rows).map(i => (
        <div key={i} className="dg--row dg--label-table--row">
          <div className="dg--cell dg--label-table--cell dg--label-table--row-label">{rows[i].label}</div>
          <div className="dg--cell dg--label-table--cell">
            {rows[i].value}
            {rows[i].table && <ComplexTable {...rows[i].table} className='dg--label-subtable' />}
          </div>
        </div>
      ))}
    </div>
  </div>
)

export default LabelTable
