import React from 'react'

import Button from './../../components/button'
import Icon from './../../components/icon'

const AppointmentError = () => (
  <div>
    <p>No client set- go back and add a client before adding an appointment.</p>
    <Button theme="primary" to="/client-add/">
      <Icon icon="client" theme="primary" modifier="plus" />
      <span className="button__label">Add Client</span>
    </Button>
  </div>
)

export default AppointmentError
