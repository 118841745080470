import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import Button from '../button'
import Icon from '../icon'
import ClientAPIContext from '../../services/client';

const ScheduleApptButton = ({ number }) => {
  const clientAPI = useContext(ClientAPIContext);
  const client = clientAPI.id;

  const addAppointment = () => {
    const redirect = `/client/${client}`

    navigate(
      `/client/${client}/edit/appt/${number}/`,
      {
        state: {
          client,
          redirect
        }
      }
    );
  }

  return (<Button theme="secondary" onClick={addAppointment}>
    <Icon theme="secondary" icon="calendar" modifier="plus" />
    <span>
      Add Appointment
    </span>
  </Button>)
}

ScheduleApptButton.propTypes = {
  number: PropTypes.string.isRequired
}

export default ScheduleApptButton
