import React, { useContext } from 'react'

import { navigate } from 'gatsby'

import ClientContext from '../../services/client';

import Card from '../card'
import Button from './../button'
import Icon from './../icon'

const Complete = ({ client, next }) => {
  const clientAPI = useContext(ClientContext);

  const name = clientAPI.data
    ? clientAPI.data.name
    : ' ';

  const email = clientAPI.data
    ? clientAPI.data.email
    : ' ';

  return (
    <div className="container-panel">
      <Card>
        <div className="card--inner">
          <h2>Client Added!</h2>
          <p><strong>{name}</strong> has been added as a client.</p>
        </div>
        <div className="card--inner card--divider">
          <Button theme="primary" onClick={() => navigate(next)}>
            <span
              className="button__label"
            >
              Client Details
            </span>
            <Icon icon="forward" theme="primary" />
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default Complete
