import React, { useContext } from 'react'
import ClientContext from '../../services/client';
import ClientName from './name';

const ClientPageTitle = () => {
  const clientAPI = useContext(ClientContext);

  return clientAPI.data ? (
    <ClientName
      name={clientAPI.data.name}
      folder={clientAPI.data.folder}
      id={clientAPI.data.id}
      modifiers={['page-title']}
    />
  ) : null;
}

export default ClientPageTitle
