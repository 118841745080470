import React, { useContext, useState, useEffect, useRef } from 'react'
import Button from '../../../button'
import Icon from '../../../icon'
import MessagesContext from '../../../../context/messages'
import NewClientOrderDetail from '../../../../components/order/NewClientOrderDetail'
import OrderAPIContext, { OrderAPIProvider } from '../../../../services/order'
import OrderUpdateAPIContext, {
  OrderUpdateAPIProvider,
} from '../../../../services/orderUpdate'
import Orders from '../Orders'
import ProgressBar from '../../../progressBar'
import PageTitle from '../../../pageTitle'
import { navigate } from 'gatsby'

const AddPaymentInner = ({ client, orderId }) => {
  const { data: orderData } = useContext(OrderAPIContext)
  const orderUpdateAPI = useContext(OrderUpdateAPIContext)
  const { setMessages } = useContext(MessagesContext)

  // Should we display the "Pay Later Button"?
  if (orderData) {
    var displayPayLater = orderData.remaining == '0' ? false : true
  }

  // Hold current state of order
  const [order, setOrder] = useState({
    adjustments: [],
    coupons: [],
    items: [],
  })

  // Control Continue buttons
  const [disableContinue, setDisableContinue] = useState(true)

  //Update data once orderAPI is reloaded
  useEffect(() => {
    if (orderData) {
      setOrder(orderData)
      // Set default
      setDisableContinue(true)
      // If payments have been made enable continue button or remaining balance is zero when created
      if (orderData.payments.length > 0 || orderData.remaining == 0) {
        setDisableContinue(false)
      }
    }
  }, [orderData])

  const updateOrderPayLater = () => {
    if (orderData !== 'payment_deferred') {
      var tempOrder = {
        status: 'payment_deferred',
      }

      //Update existing order with changes
      var orderData = { ...order, ...tempOrder }

      orderUpdateAPI.load(client, orderId, orderData)
    }

    doContinue()
  }

  const doContinue = () => {
    // If new order was created, clear left over messages
    setMessages([])
    navigate(`/client/${client}/edit/complete`)
  }

  return (
    <>
      <ProgressBar currentStep={'5'} client={client}></ProgressBar>
      <PageTitle />
      <Orders
        id={client}
        path={`/client/${client}/edit/payment/`}
        allowEdit={true}
      >
        {/* If an order is selected navigate to that order, otherwise default to add */}
        <NewClientOrderDetail
          clientId={client}
          orderId={orderId}
          allowEdit={true}
        />
        {/* Allow user to navigate to final screen after order created */}
        {orderId && (
          <div className="card--inner card--divider layout-add-submit">
            <div className="button-group">
              <Button
                disabled={disableContinue}
                theme="primary"
                type="submit"
                onClick={() => doContinue()}
              >
                <span className="button__label">Continue</span>
                <Icon icon="forward" theme="primary" />
              </Button>
              {displayPayLater ? (
                <Button
                  disabled={!disableContinue}
                  theme="secondary"
                  type="submit"
                  onClick={() => updateOrderPayLater()}
                >
                  <span className="button__label">Continue & pay later</span>
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>
        )}
      </Orders>
    </>
  )
}

const AddPayment = props => (
  <OrderAPIProvider clientId={props.client} orderId={props.orderId}>
    <OrderUpdateAPIProvider>
      <AddPaymentInner {...props} />
    </OrderUpdateAPIProvider>
  </OrderAPIProvider>
)
export default AddPayment
