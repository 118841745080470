/* eslint-disable no-magic-numbers */
import React, { useContext, useEffect } from 'react'
import * as Yup from 'yup';
import Button from '../button'
import Icon from '../icon'
import ClientInfoSendAPIContext, { ClientInfoSendAPIProvider } from '../../services/clientInfoSend';
import { Formik, Form, Field, FieldArray, getIn } from 'formik'
import FieldText from '../field/formikText'
import LoadingIndicator from '../LoadingIndicator';


const FormSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter an Email')
});

const normalizeRequest = (values, id) => ({
  email: values.email
});

const ErrorMessage = ({ name }) => (
  <Field
    name={name}
  >
    {({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);

      return ((touch && error)
        ? (<div className="field__error">{error}</div>)
        : null
      );
    }}
  </Field>
);

const ClientInfoSendFormInner = ({ id, onSuccess = () => { }, onCancel = () => { }, email = '' }) => {
  const clientInfoAPI = useContext(ClientInfoSendAPIContext);

  const {
    load,
    isPending,
    isResolved,
    data = {}
  } = clientInfoAPI;
  const { message, errors } = data;

  useEffect(() => {
    if (isResolved) {
      onSuccess();
    }
  }, [isResolved])

  const onSubmit = (values) => {
    load(id, normalizeRequest(values, id));
  };

  if (isResolved) {
    return (
    <>
      {message && (<p>{message}</p>)}
      {errors && errors.map(error => (<p>{error.detail}</p>))}
    </>
    );
  }

  if (isPending) {
    return (<LoadingIndicator text={'Sending'} />)
  }

  return (
    <>
      <p>Enter the email address you would like to send the client information form to.</p>
      <Formik
        initialValues={{
          email
        }}
        enableReinitialize={true}
        validate={values => {}}
        validationSchema={FormSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue, errors, touched, isSubmitting }) => (
          <Form>
            <div className="field field--inline">
              <div className="field__inner">
                <Field type="email"
                  id={'email'}
                  name={'email'}
                  >
                  {({ field }) => (
                    <FieldText {...field} onChange={(event) => { setFieldValue(event.target.name, event.target.value.trim()); }} label={'Email Address'} required />
                    )}
                </Field>
                <ErrorMessage name={'email'} />
              </div>
            </div>
            <div className="button-group">
              <Button theme="primary" disabled={isPending}>
                <span className="button__label" disabled={isPending}>Send</span>
              </Button>
              <Button theme="secondary" disabled={isPending} onClick={onCancel} type="button">
                <span className="button__label" disabled={isPending}>Cancel</span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>

    </>
  )
}

const ClientInfoSendForm = props => {

  return (
    <ClientInfoSendAPIProvider>
      <ClientInfoSendFormInner {...props} />
    </ClientInfoSendAPIProvider>
  );
}

export default ClientInfoSendForm;
