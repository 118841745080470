import * as faker from 'faker'

import { mockId } from './mock-clients'

const filterFour = scores => {
  if (scores.sv === 'high') {
    return {
      abbvr: 'SV',
      label: 'Structural Visualization',
      pattern: 'SV'
    }
  }
  if (scores.id === 'high') {
    return {
      abbvr: 'ID',
      label: 'Ideaphoria',
      pattern: 'ID'
    }
  }
  if (scores.ir === 'high') {
    return {
      abbvr: 'IR',
      label: 'Inductive Reasoning',
      pattern: 'IR'
    }
  }
  if (scores.ar === 'high') {
    return {
      abbvr: 'AR',
      label: 'Analytical Reasoning',
      pattern: 'AR'
    }
  }

  return false
}
const filterEmphasis = scores => {
  const emphasis = {
    num: ['ns', 'nf', 'nm', 'gra'],
    vis: ['md', 'obs', 'vdt1', 'vdt2', 'cd'],
    aud: ['tm', 'pd', 'rm'],
    dex: ['td', 'fd']
  }

  const labels = {
    num: 'Numerical',
    vis: 'Visual',
    aud: 'Auditory',
    dex: 'Dexterity'
  }

  return Object.keys(emphasis)
    .filter(key => emphasis[key].filter(aptitude => scores[aptitude] === 'high').length > 1)
    .map(key => ({
      abbvr: key,
      label: labels[key],
      pattern: emphasis[key]
        .filter(aptitude => scores[aptitude] === 'high')
        .join('+')
        .toUpperCase()
    }))
}

const filterPersonality = scores => {
  const personality = {
    obj: {
      abbvr: 'obj',
      label: 'Objective Personality',
      pattern: 'Objective'
    },
    int: {
      abbvr: 'int',
      label: 'Intermediate Personality',
      pattern: 'Intermediate'
    },
    subj: {
      abbvr: 'subj',
      label: 'Subjective Personality',
      pattern: 'Subjective'
    }
  }

  return personality[scores.personality]
}

const filterHigh = list => list.filter(item => item === 'high')

const generateJobs = () => new Array(faker.random.number({ min: 3, max: 5 }))
  .fill('job')
  .map(item => {
    const label = faker.name.jobTitle()
    return {
      id: mockId(label),
      label
  }
  })

const generateDiscussionItem = (key, value) => {
  const labels = {
    sv: 'Structural Visualization',
    id: 'Ideaphoria',
    ir: 'Inductive Reasoning',
    ar: 'Analytical Reasoning',
    personality: 'Personality',
    num: 'Numerical',
    vis: 'Visual',
    aud: 'Auditory',
    dex: 'Dexterity',
    vocab: 'Vocabulary',
    ns: 'Number Series',
    nf: 'Number Facility',
    nm: 'Number Memory',
    gra: 'Graphoria',
    md: 'Memory for Design',
    obs: 'Observation',
    cd: 'Color Discrimination',
    tm: 'Total Memory',
    pd: 'Pitch Discrimination',
    rm: 'Rhythm Memory',
    td: 'Tweezer Dexterity',
    fd: 'Finger Dexterity',
    fo: 'Foresight',
    silo: 'Silograms'
  }

  return labels.hasOwnProperty(key)
    ? {
      abbvr: key,
      label: `${value.toUpperCase()} ${labels[key]}`,
      pattern: `${key.toUpperCase()} ${value}`
    }
    : null
}

const generateSummary = options => ([
  {
    label: 'Core',
    value: options.primary.map(option => option.label)
  },
  {
    label: 'Emphasis',
    value: options.emphasis.map(option => option.label)
  },
  {
    label: 'Personality',
    value: options.personality.map(option => option.label)
  },
  {
    label: 'Vocabulary',
    value: options.vocabulary.map(option => option.label)
  }
])

const generateResult = options => (
  options.map(option => (
    {
      id: mockId(option.label),
      abbvr: option.abbvr,
      label: option.label,
      pattern: option.pattern,
      careers: {
        defaults: generateJobs(),
        optional: generateJobs()
      },
      display: {
        all: 'show',
        desc: 'show',
        careers: 'show'
      }
    }
  ))
)

const generateDiscussion = pattern => {

  const discussion = []

  for (const key in pattern) {
    if (pattern.hasOwnProperty(key)) {
      const item = generateDiscussionItem(key, pattern[key])

      if (item) {
        discussion.push(item)
      }
    }
  }

  return discussion
}

export const mockResults = pattern => {

  const bigfour = filterFour(pattern)
  const emphasis = filterEmphasis(pattern)
  const personality = filterPersonality(pattern)

  const options = {
    primary: bigfour
      ? [bigfour, ...personality]
      : [...emphasis, ...personality],
    emphasis,
    personality: [personality],
    vocabulary: [
      {
        label: 'Vocabulary',
        abbvr: 'vocab',
        pattern: pattern.vocab
      }
    ]
  }

  return {
    summary: generateSummary(options),
    primary: generateResult(options.primary),
    emphasis: generateResult(options.emphasis),
    discussion: generateResult(generateDiscussion(pattern))
  }
}

export const scores = [
  {
    test: {
      number: '703',
      code: 'AB',
      name: 'Graphoria'
    },
    score: '220',
    norm: '85',
    date: '11/28/2018',
    admin: 'Lisa Cook',
    inc: false,
    nt: false,
    nfr: false,
    tried: ''
  },
  {
    test: {
      number: '161',
      code: 'AO',
      name: 'Ideaphoria',
      options: {
        code: [
          '',
          'AO',
          'GO'
        ]
      }
    },
    score: '800',
    norm: '75',
    date: '11/27/2018',
    admin: 'Lisa Cook',
    inc: false,
    nt: false,
    nfr: false,
    tried: ''
  },
  {
    test: {
      number: '307',
      code: 'EQ',
      name: 'Foresight'
    },
    score: '185',
    norm: '85',
    date: '11/28/2018',
    admin: 'Lisa Cook',
    inc: false,
    nt: false,
    nfr: false,
    tried: ''
  },
  {
    test: {
      number: '307',
      code: 'EN',
      name: 'Wiggly Block',
      options: {
        number: [
          '',
          '4',
          '45',
          '455',
          '555',
          '556'
        ]
      }
    },
    score: '185',
    norm: '85',
    date: '11/28/2018',
    admin: 'Lisa Cook',
    inc: false,
    nt: false,
    nfr: false,
    tried: ''
  }
]

export const sds = [
  {
    label: 'Activities',
    R: 0,
    I: 0,
    A: 0,
    S: 0,
    E: 0,
    C: 0
  },
  {
    label: 'Competencies',
    R: 0,
    I: 0,
    A: 0,
    S: 0,
    E: 0,
    C: 0
  },
  {
    label: 'Occupations',
    R: 0,
    I: 0,
    A: 0,
    S: 0,
    E: 0,
    C: 0
  },
  {
    label: 'Self-Estimates Part 1',
    R: 0,
    I: 0,
    A: 0,
    S: 0,
    E: 0,
    C: 0
  },
  {
    label: 'Self-Estimates Part 2',
    R: 0,
    I: 0,
    A: 0,
    S: 0,
    E: 0,
    C: 0
  }
]

export const summary = [
  {
    label: 'Core',
    value: ['SV']
  },
  {
    label: 'Emphasis',
    value: [
      'Visual',
      'Auditory'
    ]
  },
  {
    label: 'Personality',
    value: ['Intermediate']
  },
  {
    label: 'Vocabulary',
    value: ['High']
  }
]

export const defaultOptions = [
  {
    label: 'LOW',
    value: 'low'
  },
  {
    label: 'AVG',
    value: 'avg'
  },
  {
    label: 'HIGH',
    value: 'high'
  }
]

export const aptitudes = [
  {
    label: '',
    divider: 'none',
    aptitudes: [
      {
        id: 'personality',
        label: 'Personality',
        style: 'large',
        score: '7',
        value: 'int',
        options: [
          {
            label: 'SUBJ',
            value: 'subj'
          },
          {
            label: 'INT',
            value: 'int'
          },
          {
            label: 'OBJ',
            value: 'obj'
          }
        ]
      }
    ]
  },
  {
    label: '',
    divider: 'line',
    aptitudes: [
      {
        id: 'vocab',
        label: 'Vocabulary',
        style: 'large',
        score: '85',
        value: 'high',
        options: defaultOptions
      }
    ]
  },
  {
    label: '',
    divider: 'line',
    aptitudes: [
      {
        id: 'sv',
        label: 'SV',
        style: 'small',
        score: '80',
        value: 'high',
        options: defaultOptions
      },
      {
        id: 'id',
        label: 'ID',
        style: 'small',
        score: '55',
        value: 'avg',
        options: defaultOptions
      },
      {
        id: 'ir',
        label: 'IR',
        style: 'small',
        score: '10',
        value: 'low',
        options: defaultOptions
      },
      {
        id: 'ar',
        label: 'AR',
        style: 'small',
        score: '55',
        value: 'avg',
        options: defaultOptions
      }
    ]
  },
  {
    label: 'Numbers',
    divider: 'line',
    aptitudes: [
      {
        id: 'ns',
        label: 'NS',
        style: 'small',
        score: '70',
        value: 'high',
        options: defaultOptions
      },
      {
        id: 'nf',
        label: 'NF',
        style: 'small',
        score: '30',
        value: 'low',
        options: defaultOptions
      },
      {
        id: 'nm',
        label: 'NM',
        style: 'small',
        score: '10',
        value: 'low',
        options: defaultOptions
      },
      {
        id: 'gra',
        label: 'GRA',
        style: 'small',
        score: '15',
        value: 'low',
        options: defaultOptions
      }
    ]
  },
  {
    label: 'Visual',
    divider: 'none',
    aptitudes: [
      {
        id: 'md',
        label: 'MD',
        style: 'small',
        score: '90',
        value: 'high',
        options: defaultOptions
      },
      {
        id: 'obs',
        label: 'OBS',
        style: 'small',
        score: '30',
        value: 'low',
        options: defaultOptions
      },
      {
        id: 'vdt1',
        label: 'VDT1',
        style: 'small',
        score: '30',
        value: 'low',
        options: defaultOptions
      },
      {
        id: 'vdt2',
        label: 'VDT2',
        style: 'small',
        score: '30',
        value: 'low',
        options: defaultOptions
      },
      {
        id: 'cd',
        label: 'CD',
        style: 'small',
        score: '70',
        value: 'high',
        options: defaultOptions
      }
    ]
  },
  {
    label: 'Auditory',
    divider: 'none',
    aptitudes: [
      {
        id: 'tm',
        label: 'TM',
        style: 'small',
        score: '95',
        value: 'high',
        options: defaultOptions
      },
      {
        id: 'pd',
        label: 'PD',
        style: 'small',
        score: '50',
        value: 'avg',
        options: defaultOptions
      },
      {
        id: 'rm',
        label: 'RM',
        style: 'small',
        score: '85',
        value: 'low',
        options: defaultOptions
      }
    ]
  },
  {
    label: 'Dexterity',
    divider: 'line',
    aptitudes: [
      {
        id: 'td',
        label: 'TD',
        style: 'small',
        score: '45',
        value: 'high',
        options: defaultOptions
      },
      {
        id: 'fd',
        label: 'FD',
        style: 'small',
        score: '10',
        value: 'avg',
        options: defaultOptions
      }
    ]
  },
  {
    label: '',
    divider: 'line',
    aptitudes: [
      {
        id: 'fo',
        label: 'Foresight',
        style: 'small',
        score: '80',
        value: 'high',
        options: defaultOptions
      },
      {
        id: 'silo',
        label: 'Silograms',
        style: 'small',
        score: '30',
        value: 'low',
        options: defaultOptions
      },
      {
        id: 'color-vision',
        label: 'Red-Green Vision',
        style: 'small',
        score: '14',
        value: 'normal', // Normal|Borderline Deficiency|Moderate Deficiency|Severe Deficiency
        options: []
      },
      {
        id: 'grip-left',
        label: 'Grip Left',
        style: 'small',
        score: '70',
        value: 'high',
        options: defaultOptions
      },
      {
        id: 'grip-right',
        label: 'Grip Right',
        style: 'small',
        score: '90',
        value: 'high',
        options: defaultOptions
      }
    ]
  }
]
