import React from 'react'

const ToggleOption = ({ id, style, abbr=false, value, options, onChange }) => (
  options.map((option, i) => (
    <label
      key={i}
      className={`toggle-option--label ${value === option.value ? 'toggle-option--label__is-selected' : ''}`}
    >
      <span>{abbr
        ? option.label.substring(0, 1)
        : option.label}
      </span>
      <input
        type="radio"
        name={id}
        value={option.value}
        checked={value === option.value}
        onChange={onChange}
      />
    </label>
  ))
)

export default ToggleOption
