/**
 *  API: Send Report
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request'

const route = id => `/api/clients/${id}/send/clientinfo`;

export const clientInfoSend = (id, data) => request(route(id), {
  method: 'POST',
  data
});

// Create a Context
const ClientInfoSendAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return {};
}

export const ClientInfoSendAPIProvider = ({ children }) => {
  const loader = useLoads('clientInfo-send', clientInfoSend, {
    defer: true
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  return (
    <ClientInfoSendAPIContext.Provider value={Object.assign({}, loader, {
      data
    })}>
      {children}
    </ClientInfoSendAPIContext.Provider>
  )
}

ClientInfoSendAPIProvider.propTypes = {
  children: PropTypes.element
}

export default ClientInfoSendAPIContext;
