import React from 'react'

const Codes = ({ summary }) => (
  <div className="sds-codes">
    <h3>Summary Code</h3>
    <ol>
      {Object.keys(summary).map(key => <li key={key}><span>{summary[key]}</span></li>)}
    </ol>
  </div>
)

export default Codes