import React, { useContext, useState } from 'react';
import { summaryReportReset, summaryReportSave } from '../../../services/summaryReportUpdate';
import SummaryReportAPIContext from '../../../services/summaryReport';
import SummaryPatternAPIContext from '../../../services/summaryPattern';
import { navigate } from 'gatsby';
import Button from '../../button';
import { MessageCard } from './summary';

const ReportGenerateBase = (id) => {
  const [
    disabled,
    setDisabled
  ] = useState(false);

  const reportAPI = useContext(SummaryReportAPIContext);
  const patternAPI = useContext(SummaryPatternAPIContext);

  const reload = () => {
    reportAPI.load(id);
    reportAPI.settingsLoader.load();
    patternAPI.load(id);
  }

  return {disabled, setDisabled, reportAPI, patternAPI, reload }

}

const RegenerateReportInner = ({id, setMessages} = props) => {

  const { disabled, setDisabled, reportAPI, reload } = ReportGenerateBase(id);

  function onSaveError(error) {
    let errors = [];
    if (error.response && error.response.errors) {
      errors = errors.concat(Object.values(error.response.data.errors).map(err => err.title))
    } else if (error.status) {
      errors.push(error.status);
    }

    reload()
    setSubmitting(false);
    setMessages(errors.map(err => ({
      type: 'error',
      text: err
    })));
  }

  function onSaveSuccess(response, successMessage) {
    let status = [];
    if (response.data && response.data.errors.length > 0) {
      status.push(response.data.errors);
    }
    else {
      status.push({
        type: 'status',
        text: successMessage
      });
    }
    reload()
    setMessages(status);
    navigate(`/client/${id}/summary`);
  }

  return <MessageCard>
    <p className="message-overlay__text">One or more scores have been changed since the report was generated. Do you want to update the report content based on the new scores? Any content changes will be lost.<br /><br />
      Hit <b>Cancel</b> to continue editing the report where you left off, or <b>Regenerate</b> to reset the report content.</p>
    <div className="button-group button-group--centered">
      <Button theme="primary" disabled={disabled} onClick={() => {
        setDisabled(true);
        summaryReportSave(id, { contentUpdateRequired: 0 })
          .then(response => {
            let status = [];
            if (response.data.errors) {
              status.push(response.data.errors);
            }
            else {
              status.push({
                type: 'status',
                text: 'Test score changes ignored. No changes have been made to the report summary.'
              });
              reportAPI.load()
            }
            setMessages(status);
          })
          .catch(error => {
            let errors = [];
            if (error.response && error.response.errors) {
              errors = errors.concat(Object.values(error.response.data.errors).map(err => err.title));
            } else if (error.status) {
              errors.push(error.status);
            }

            setMessages(errors.map(err => ({
              type: 'error',
              text: err
            })));
          });

        setDisabled(false);
      }}>
        <span className="button__label">Cancel</span>
      </Button>

      <Button theme="secondary" disabled={disabled} onClick={() => {
        setDisabled(true);
        summaryReportReset(id, {})
          .then(response => {
            onSaveSuccess(response, 'The report has been re-generated.');
          })
          .catch(error => {
            onSaveError(error)
          });
      }}><span className="button__label">Regenerate</span></Button>
    </div>
  </MessageCard>;
}

export const RegenerateReport = props => (
  <RegenerateReportInner {...props} />
);


export const InitializeReport = ({id, setMessages}) => {

  const { disabled, setDisabled, reload } = ReportGenerateBase(id);

  return <MessageCard>
    <p className="message-overlay__text">Are you ready to generate a report?</p>
    <div className="button-group button-group--centered">
      <Button disabled={disabled} theme="secondary" onClick={() => {
        navigate(`/client/${id}/scores/`);
      }}>
        <span className="button__label">Go back/continue editing scores</span>
      </Button>

      <Button disabled={disabled} theme="primary" onClick={() => {
        setDisabled(true);
        summaryReportSave(id, { generateNewReport: 1 })
          .then(response => {
            let status = [];
            if (response.data.errors) {
              status.push(response.data.errors);
            }
            else {
              status.push({
                type: 'status',
                text: 'Report successfully generated.'
              });
              reload();
            }
            setMessages(status);
          })
          .catch(error => {
            let errors = [];
            if (error.response && error.response.errors) {
              errors = errors.concat(Object.values(error.response.data.errors).map(err => err.title));
            } else if (error.status) {
              errors.push(error.status);
            }

            reload()

            setMessages(errors.map(err => ({
              type: 'error',
              text: err
            })));
          });

        setDisabled(false);
      }}>
        <span className="button__label">Generate report</span>
      </Button>
    </div>
  </MessageCard>;
}
