import React, {
  useContext,
  useEffect,
  useState
} from 'react'

import PaymentsAPIContext from '../../services/payments'
import TanStackTable from '../TanStackTable'
import Currency from '../currency'
import DateLabel from '../date/label'

import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import ErrorBoundary from '../ErrorBoundary'
import LoadingIndicator from '../LoadingIndicator'

const columnHelper = createColumnHelper()

const getColumns = data => [
  columnHelper.accessor('date', {
    cell: info => <DateLabel date={info.getValue()} />,
    enableSorting: true,
    meta: {
      headerProps: {
        className: 'rt-header_left',
      },
      footerProps: {
        className: 'rt-footer--summary',
      }
    },
  }),
  columnHelper.accessor('method', {
    header: () => 'Method',
    enableSorting: true,
    maxSize: 300,
    meta: {
      headerProps: {
        className: 'rt-header_left',
      }
    }
  }),
  columnHelper.accessor('statusLabel', {
    enableSorting: true,
    footer: props => <span>Remaining Balance:</span>,
    header: () => 'Status',
    maxSize: 300,
    meta: {
      cellProps: {
        className: 'rt-td--status',
      },
      headerProps: {
        className: 'rt-header_left',
      },
      footerProps: {
        className: 'rt-footer--summary',
      }
    }
  }),
  columnHelper.accessor('amount', {
    cell: info => <Currency amount={info.getValue()} />,
    enableSorting: true,
    footer: props => (
      <Currency modifier={'large'} amount={data.remaining || 0} />
    ),
    header: () => 'Amount',
    maxSize: 300,
    meta: {
      cellProps: {
        className: 'rt-td--amount',
      },
      footerProps: {
        className: 'rt-footer--summary',
      }
    },
  })
]

const PaymentsListInner = (updateDisplay) => {
  const api = useContext(PaymentsAPIContext)
  const { data } = api
  const [sorting, setSorting] = useState([])
  const table = useReactTable({
    data: data && data.payments ? data.payments : [],
    columns: getColumns(data),
    defaultColumn: {
      size: 100,
      enableSorting: false,
    },
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  // Update payment display when order has changed
  useEffect(() => {
    api.load()
  }, [updateDisplay])

  return (
    <>
      <TanStackTable
        table={table}
        noDataText={'No transactions found'}
      />
    </>
  )
}

const PaymentsList = props => <ErrorBoundary><PaymentsListInner {...props} /></ErrorBoundary>

export default PaymentsList
