/**
 *  API: orderDelete
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request'

const route = (clientId, orderId) => `/api/clients/${clientId}/orders/${orderId}`;

export const orderDelete = (clientId, orderId, data) => request(route(clientId, orderId), {
  method: 'delete',
  data
});

// Create a Context
const OrderDeleteAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return {};
}

export const OrderDeleteAPIProvider = ({ children }) => {
  const loader = useLoads('order-delete', orderDelete, {
    defer: true
  });
  // Raw response data
  const data = getDataFromResponse(loader.response);

  return (
    <OrderDeleteAPIContext.Provider value={ Object.assign({}, loader, {
      data
    })}>
      {children}
    </OrderDeleteAPIContext.Provider>
  )
}

OrderDeleteAPIProvider.propTypes = {
  children: PropTypes.element
}

export default OrderDeleteAPIContext
