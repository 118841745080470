import * as faker from 'faker'
import { names, generateAppointments } from './mock-calendar'

export const mockId = name => {

  let id = 0

  for (let i=0; i < name.length; i++) {
    id += name.charCodeAt(i)
  }

  return id
}

export const mockPayment = amount => {

  const paid = faker.random.number({min:0, max: amount / 100 }) * 100

  return {
    "amount": paid,
    "remaining": amount - paid,
    "complete": amount === paid
  }
}

// export const data = [{
//   name: 'Larry Taylor',
//   folder: '230882-18',
//   status: {
//     appointment1: {
//       date: '2/28/18',
//       completed: true
//     },
//     appointment2: {
//       date: '2/28/18',
//       completed: true
//     },
//     appointment3: {
//       date: '3/01/18',
//       completed: false
//     }
//   }
// },
// {
//   name: 'Alexander Townsend',
//   folder: '230883-18',
//   status: {
//     appointment1: {
//       date: '2/28/18',
//       completed: true
//     },
//     appointment2: {
//       date: '2/28/18',
//       completed: true
//     },
//     appointment3: {
//       date: '3/01/18',
//       completed: false
//     }
//   }
// },
// {
//   name: 'Michael Graham',
//   folder: '230884-18',
//   status: {
//     appointment1: {
//       date: '2/28/18',
//       completed: true
//     },
//     appointment2: {
//       date: '2/28/18',
//       completed: true
//     },
//     appointment3: {
//       date: '3/01/18',
//       completed: false
//     }
//   }
// },
// {
//   name: 'Michael Daniels',
//   folder: '230885-18',
//   status: {
//     appointment1: {
//       date: '2/28/18',
//       completed: true
//     },
//     appointment2: {
//       date: '2/28/18',
//       completed: true
//     },
//     appointment3: {
//       date: '3/01/18',
//       completed: false
//     }
//   }
// },
// {
//   name: 'Garrett Terry',
//   folder: '230886-18',
//   status: {
//     appointment1: {
//       date: '2/28/18',
//       completed: true
//     },
//     appointment2: {
//       date: '2/28/18',
//       completed: true
//     },
//     appointment3: {
//       date: '3/01/18',
//       completed: false
//     }
//   }
// }]

export const data = names.map(client => ({
  id: mockId(client.name),
  name: client.name,
  folder: client.folder,
  appointments: generateAppointments(),
  info: {
    complete: faker.random.boolean()
  },
  payment: mockPayment(700)
}))
