import React, { useContext } from 'react'
import moment from 'moment-timezone';
import LocationContext from './../../context/location';

const DayTimeLabel = ({ date }) => {
  const location = useContext(LocationContext);

  return (
    <span className="date--day" data-date={date}>{moment.utc(date.start).tz(location.timezone(location.id)).format('ddd h:mmA')}&mdash;{moment.utc(date.end).tz(location.timezone(location.id)).format('h:mmA')}</span>
)};

export default DayTimeLabel
