import React from 'react'
import PropTypes from 'prop-types'

import { Link } from "@reach/router"
import CustomLink from './Link';

const MenuTab = props => (
  <CustomLink
    {...props}
    getProps={({ isCurrent, isPartiallyCurrent }) => ({
      className: `menu-tab ${isCurrent || (props.partial === 1 && isPartiallyCurrent)
        ? "menu-tab__is-active"
        : "menu-tab__is-inactive"
      }`
    })}
  />
)

MenuTab.propTypes = {
  partial: PropTypes.number
}

export default MenuTab
