/**
 * Returns highest value key
 * @param {object} totals
 *   {A: 1, B: 2, C: 3 }
 *
 * @returns {string}
 *   'C'
 */
export const maxKey = totals => (
  Object.keys(totals).reduce((a, b) => (
    totals[a] > totals[b]
      ? a
      : b
  ))
)

/**
 * Returns highest value key or ties
 * @param {object} totals
 *   {A: 1, B: 3, C: 3 }
 *
 * @returns {array}
 *   ['B', 'C']
 */
export const maxKeys = totals => (
  Object.keys(totals).filter(key => {
    return totals[key] === Math.max(...Object.values(totals))
  })
)

/**
 * Split off maxKeys from totals
 * @param {object} totals
 * @param {array} ordered
 *
 * @returns {array}
 *   list of maxKeys in order
 */
export const orderKeys = (totals, ordered = []) => {

  // Make a copy of totals to safely delete properties.
  const totalsCopy = Object.assign({}, totals)
  const max = maxKeys(totalsCopy)

  max.forEach(key => {
    Reflect.deleteProperty(totalsCopy, key)
  })

  ordered.push(max)

  // Call orderKeys until totals empty.
  if (Object.keys(totalsCopy).length > 0) {

    orderKeys(totalsCopy, ordered)
  }

  return ordered
}

/**
 * Top 3 keys from totals.
 * @param {object} totals
 *
 * @returns {object}
 */
export const summary = totals => {

  const ordered = orderKeys(totals).map(key => key.join(''))

  // Return same shape object but blank until there are useful values.
  return Object.keys(ordered).length > 1
    ? { 1: ordered[0], 2: ordered[1], 3: ordered[2] }
    : { 1: '', 2: '', 3: '' }
}

export const total = (list, index) => list.reduce((acc, cur) => {
  // isNaN('abc') is true, but isNaN('') is false. ¯\_(ツ)_/¯
  const value = (typeof cur[index] === 'string')
    ? parseInt(cur[index])
    : cur[index]

  return acc + value
}, 0)
