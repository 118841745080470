/**
 *  API: scores
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request'

const route = id => `/api/clients/${id}/scores`;

export const scoresLoad = id => () => request(route(id));

// Create a Context
const ScoresAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return {};
}

const getScores = response => {
  if (response && response.data) {
    return response.data.scores;
  }

  return [];
}

const getscore = (response, number) => {
  const scores = getScores(response);

  return scores.find((score => score.score === number));
}

export const ScoresAPIProvider = ({ children, id }) => {
  const loader = useLoads('scores', scoresLoad, {
    variables: [id],
    defer: true
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  return (
    <ScoresAPIContext.Provider value={ Object.assign({}, loader, {
      id,
      data,
      getScores: () => getScores(loader.response),
      getscore: number => getscore(loader.response, number)
    })}>
      {children}
    </ScoresAPIContext.Provider>
  )
}

ScoresAPIProvider.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.element
}

export default ScoresAPIContext
