import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types';
import Select from './select';
import AdjustmentsAPIContext from '../../services/adjustments';


const AdjustmentSelect = props => {
  const adjustmentsAPI = useContext(AdjustmentsAPIContext);
  const { isResolved, isIdle, options } = adjustmentsAPI;

  // Refetch the client on each page load.
  useEffect(() => {
    // Load if we
    if (isIdle) {
      adjustmentsAPI.load();
    }
  }, [window.location.pathname]);

  if (!isResolved) {
    return (<span>Loading</span>);
  }

  return (
    <Select
      {...props}
      nonOption="Select a Adjustment"
      options={options}
      className="field-select--tight"
    >
    </Select>
  );
};

AdjustmentSelect.propTypes = {};

AdjustmentSelect.defaultProps = {
  options: []
};

export default AdjustmentSelect;
