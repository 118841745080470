import React, {Component} from 'react'

import Icon from '../icon'

class FilterPane extends Component {

  constructor() {
    super()
    this.state = {
      init: true,
      open: true
    }
  }

  toggle = () => {
    if (!this.state.init) {
      // Only add the closed class after first toggle to prevent page load animation.
      this.setState({init: true});
    }
    this.setState({open: !this.state.open});
  }

  render() {
    const {title, animate = true, children} = this.props;

    return (
      <div className={`filter-pane ${this.state.init ? (this.state.open ? 'is-open' : 'is-closed') : ''}`}>
        <div className="filter-pane--header is_top layout--pad1-b">
          {title}
          {!!children && (
            <div
            onClick={this.toggle}
            className="filter-pane--toggle"
            >
              {this.state.open
                ? <Icon theme="primary" icon="arrowCircleDown" />
                : <Icon theme="primary" icon="arrowCircleUp" />
              }
            </div>
          )}
        </div>
        {!!children && (
          <div className={`${animate ? "filter-pane--content" : "filter-pane--content-all"}`}>
            <div className="filter-pane--content-group">
              {children}
            </div>
          </div>
        )}
      </div>
    )
  }

}

export default FilterPane
