/**
 *  API: clientInfoFinal
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request'

const route = id => `/api/clients/${id}/final`;

export const clientInfoFinalSave = (id, data) => request(route(id), {
  method: 'post',
  data
});

// Create a Context
const ClientInfoFinalUpdateAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return {};
}

export const ClientInfoFinalUpdateAPIProvider = ({ children }) => {
  const loader = useLoads('client-info-final-update', clientInfoFinalSave, {
    defer: true
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  return (
    <ClientInfoFinalUpdateAPIContext.Provider value={ Object.assign({}, loader, {
      data
    })}>
      {children}
    </ClientInfoFinalUpdateAPIContext.Provider>
  )
}

ClientInfoFinalUpdateAPIProvider.propTypes = {
  children: PropTypes.element
}

export default ClientInfoFinalUpdateAPIContext
