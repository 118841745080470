import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../button'
import Icon from '../icon'
import ClientAPIContext from '../../services/client';
import ReportDownloadAPIContext, { ReportDownloadAPIProvider } from '../../services/reportDownload';
import LoadingIndicator from '../LoadingIndicator';


const ExportReportButtonInner = ({ disabled }) => {
  const reportDownloadAPI = useContext(ReportDownloadAPIContext);
  const {
    downloadReportFile,
    load,
    isIdle,
    isPending,
    isResolved,
    data = {}
  } = reportDownloadAPI;
  const { status = 'idle' } = data;
  const clientAPI = useContext(ClientAPIContext);
  const clientID = clientAPI.id;
  const [requested, setRequested] = useState(false);

  // Run this effect when the PDF status changes.
  useEffect(() => {
    // Only download if the user explicitly requested it.
    if (isResolved && requested && status === 'downloaded') {
      // downloadFile();
      downloadReportFile();
      // Reset requested state.
      setRequested(false);
    }
  }, [requested, isResolved]);

  const requestReport = () => {
    setRequested(true);

    load(clientID, {
      async: false,
    });
  }

  return (<Button theme="secondary" onClick={requestReport} disabled={disabled || isPending}>
    <span className="button__label">Download</span>
    {isPending
      ? (<div className="loading__icon loading__icon--small"></div>)
      : (<Icon theme="secondary" icon="note" />)
    }
  </Button>)
}

ExportReportButtonInner.propTypes = {
  disabled: PropTypes.bool
}

ExportReportButtonInner.defaultProps = {
  disabled: false
}

const ExportReportButton = props => (
<ReportDownloadAPIProvider>
  <ExportReportButtonInner {...props}></ExportReportButtonInner>
</ReportDownloadAPIProvider>
);

export default ExportReportButton
