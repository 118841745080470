import React from 'react'
import PropTypes from 'prop-types';

const FieldDate = ({
  id,
  label,
  value,
  step,
  placeholder,
  disabled,
  autoFocus,
  min,
  max,
  onChange,
  onBlur,
  tabIndex
}) => (
  <div className="field-text field-date">
    <label className="field-text--label" htmlFor={id}>{label}</label>
    <div className="field-text--input">
      <input
        type="date"
        id={id}
        name={id}
        disabled={disabled}
        autoFocus={autoFocus}
        placeholder={placeholder}
        value={value}
        step={step}
        onChange={onChange}
        onBlur={onBlur}
        min={min}
        max={max}
        tabIndex={tabIndex}
      />
    </div>
  </div>
);

FieldDate.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  step: PropTypes.number,
  placeholder: PropTypes.number,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
}

FieldDate.defaultProps = {
  id: '',
  label: '',
  value: '',
  step: 1,
  placeholder: 0,
  disabled: false,
  autoFocus: false,
  onChange: () => ({}),
  onBlur: () => ({})
}

export default FieldDate
