import React, { useContext } from 'react'

import Appointment from '../../../appointment'
import AppointmentError from '../../../appointment/error'
import ProgressBar from '../../../progressBar'

import getWeekRange, { getWeekRangeFromUrl, getWeekRangeFromDate } from '../../../../utils/getWeekRange'
import { dig } from '../../../../utils/objects'
import LocationContext from '../../../../context/location';
import PageTitle from '../../../pageTitle';
import ClientAPIContext from '../../../../services/client';

const AddAppt = ({ client, number = '1', location }) => {
  const loc = useContext(LocationContext);
  const clientAPI = useContext(ClientAPIContext);
  const lastAppointment = clientAPI.getLastAppointment(number);
  const lastAppointmentDate = lastAppointment
    ? lastAppointment.date.start
    : new Date();

  const title = clientAPI.data
    ? (<><span className="page--header-title__prefix">Scheduling for:</span> <span className="page--header-title__value">{clientAPI.data.name}</span></>)
    : 'Unknown';

  // Convert string to number, add 1, convert back to string
  const step = `${(parseInt(number, 10) + 1)}`;

  const getNextPage = () => {
    let redirect = dig(location, 'redirect');

    if (!redirect) {
      redirect = number === '3'
        ? `/client/${client}/edit/payment/`
        : `/client/${client}/edit/appt/${step}`
    }

    return redirect;
  }

  // Set the weekRange to match what's in the url otherwise
  //  set it to the week of this client's most recent appointment
  const range = getWeekRangeFromUrl(location)
    || getWeekRangeFromDate(lastAppointmentDate, loc.timezone());

  const path = location.pathname

  return (
    <>
      <ProgressBar currentStep={step} client={client}></ProgressBar>
      <PageTitle title={title} modifiers={['small']}/>
      {client
        ? <Appointment number={number} path={path} start={range.start} end={range.end} client={client} next={getNextPage()} />
        : <AppointmentError />
      }
    </>
  )
}

export default AddAppt
