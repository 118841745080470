import React from 'react'

import DateLabel from '../date/label'
import DayTimeLabel from '../date/dayTime'
import Icon from '../icon'
import ScheduleApptButton from './scheduleApptButton';
import RescheduleApptButton from './rescheduleApptButton';
import CancelApptButton from './../appointment/cancelApptButton';

const AppointmentActions = ({appointment, number, refresh}) => {
  if (!appointment) {
    return (<ScheduleApptButton number={number} />);
  }
  return (<div className="button-group">
    <RescheduleApptButton number={number} appointment={appointment} />
    <CancelApptButton number={number} id={appointment.id} refresh={refresh} />
  </div>);
};

const Appointment = ({ appointment, number, slot, refresh}) => {
  const date = appointment ? appointment.date : null;
  const location = appointment ? appointment.location : null;

  return (
    <div className="client-appointment dg--row dg--center-ruled--row">
      <div className="dg--cell dg--center-ruled--cell">
        <Icon icon="appt" theme={number} value={number} />
      </div>
      <div className="dg--cell dg--center-ruled--cell">
        {date && <DateLabel date={date.start} />}
        {date && <DayTimeLabel date={date} />}
        <span className="cell-value date--slot">{slot}</span>
      </div>
      <div className="dg--cell dg--center-ruled--cell">
        {location && (<>
          <Icon icon="office" />
          <span className="cell-value">{location.label}</span>
          </>
        )}
      </div>
      <div className="dg--cell dg--center-ruled--cell">
        <AppointmentActions appointment={appointment} number={number} refresh={refresh} />
      </div>
    </div>
)};

export default Appointment;
