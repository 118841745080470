import React from 'react'

import { navigate } from 'gatsby'

import PageTitle from '../pageTitle'
import Calendar from '../calendar'
import Dialog from '../dialog'

const Appointment = ({ client, number, start, end, path }) => {

  const { id, firstname, lastname } = client

  return (
    <>
    <div className="visually-hidden">
      <PageTitle title={`Add Appointment ${number}`}>
        <p>Client: {firstname} {lastname} [{id}]</p>
      </PageTitle>
    </div>

    <Calendar client={client} path={path} start={start} end={end} add={true} number={number} />
    <Dialog />
    </>
  )
}

export default Appointment
