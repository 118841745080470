import React from 'react'

import Card from '../../card'
import MenuTab from '../../menuTab'
import { TasAPIProvider } from './../../../services/tas';

const Scores = props => {
  return <TasAPIProvider>
    <Card modifier="card--page">
        <nav className="menu-list">
          <ul>
            <li>
              <MenuTab to={'./'}>
                Aptitude / Knowledge
              </MenuTab>
            </li>
            <li>
              <MenuTab to={props.path + '/sds'}>
                Self-Directed Search
              </MenuTab>
            </li>
          </ul>
        </nav>
        {props.children}
    </Card>
  </TasAPIProvider>
}

export default Scores
