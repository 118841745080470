import React from 'react'

const SummaryAbstract = ({ title = '', items = []}) => (
  <div className="card--inner-inset">
    <h3 className="title">{title}</h3>
    <div className="summary-items">
      {items.map((item, i) => (
        <div key={i} className="summary--item">
          <strong>{item.label}</strong>
          <ul>
            {[].concat(item.value).map((value, j) => (
              <li key={j}>{value}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  </div>
)

export default SummaryAbstract
