import React from 'react'

const TitleCard = ({ title, children }) => (
  <dl className="title-card">
    <dt>{title}</dt>
    <dd>{children}</dd>
  </dl>
)

export default TitleCard
