/**
 *  API: summaryPattern
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request'

const FileDownload = require('js-file-download');

const route = id => `/api/clients/${id}/report-download`;

export const reportDownload = (id, data) => request(route(id), {
  method: 'post',
  data
});

// Create a Context
const ReportDownloadAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return {};
}

const downloadReportFile = data => {

  const { url } = data;

  if (!url) {
    return () => {};
  }

  return () => {
    request(url, {
      method: 'get',
      responseType: 'blob'
    }).then((response) => {
      FileDownload(response.data, url.split('/').slice(-1)[0], 'application/pdf');
    });
  }
}

export const ReportDownloadAPIProvider = ({ children }) => {
  const loader = useLoads('report-download', reportDownload, {
    defer: true,
    enableBackgroundStates: true,
    loadPolicy: 'load-only'
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  return (
    <ReportDownloadAPIContext.Provider value={ Object.assign({}, loader, {
      data,
      downloadReportFile: downloadReportFile(data)
    })}>
      {children}
    </ReportDownloadAPIContext.Provider>
  )
}

ReportDownloadAPIProvider.propTypes = {
  children: PropTypes.element
}

export default ReportDownloadAPIContext
