/**
 *  API: summaryReport
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request'
import { get } from '../utils/objects';

const route = id => `/api/clients/${id}/summary-report`;
const routeSettings = id => `/api/clients/${id}/summary-settings`;

export const summaryReportLoad = id => () => request(route(id));
export const summarySettingsLoad = id => () => request(routeSettings(id));

// Create a Context
const SummaryReportAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  return get(response, 'data', {})
}

export const SummaryReportAPIProvider = ({ children, id }) => {
  const loader = useLoads('summary-report', summaryReportLoad, {
    variables: [id],
    defer: false,
    loadPolicy: 'load-only'
  });

  const settingsLoader = useLoads('summary-settings', summarySettingsLoad, {
    variables: [id],
    defer: false
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  const settings = getDataFromResponse(settingsLoader.response);

  return (
    <SummaryReportAPIContext.Provider value={ Object.assign({}, loader, { settingsLoader: settingsLoader }, {
      id,
      data,
      settings
    })}>
      {children}
    </SummaryReportAPIContext.Provider>
  )
}

SummaryReportAPIProvider.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.element
}

export default SummaryReportAPIContext
