import React, { useContext } from 'react'

import Button from '../button'
import Icon from '../icon'
import DialogContext from '../../context/dialog';
import CancelApptForm from './cancelApptForm';

const CancelApptButton = ({ refresh, id }) => {
  const dialog = useContext(DialogContext);

  const text = 'Cancel';
  const modifier = 'remove';
  const heading = 'Cancel Appointment';

  const onClick = () => {
    dialog.openWith({
      heading,
      content: <CancelApptForm onSuccess={refresh} onCancel={dialog.close} id={id}/>,
    });
  }

  return (
    <div className={`calendar__cell calendar-appt-cancel`}>
      <Button theme="secondary" onClick={onClick}>
        <Icon icon="calendar" theme="secondary" modifier={modifier} />
        <span
          className="button__label"
        >
          {text}
        </span>
      </Button>
    </div>
  )
}

export default CancelApptButton
