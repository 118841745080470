import React, { useContext, useEffect, useRef, useState } from 'react'
import Button from '../button'
import AdjustmentsAPIContext from '../../services/adjustments'
import AdjustmentSelect from '../field/AdjustmentSelect'
import CouponsAPIContext from '../../services/coupons'
import CouponSelect from '../field/CouponSelect'
import DialogContext from '../../context/dialog'
import FieldCheckbox from '../field/checkbox'
import Icon from '../icon'
import LoadingIndicator from '../LoadingIndicator'
import Messages from '../messages'
import MessagesContext from '../../context/messages'
import OrderAddAPIContext, {
  OrderAddAPIProvider,
} from '../../services/orderAdd'
import OrderAPIContext from '../../services/order'
import OrderTable from './OrderTable'
import OrdersAPIContext from '../../services/orders'
import { PaymentsAPIProvider } from '../../services/payments'
import PaymentAdd from '../payment/PaymentAdd'
import PaymentsList from '../payment/PaymentsList'
import ProductsAPIContext from '../../services/products'
import ProductSelect from '../field/ProductSelect'
import { Redirect, useLocation } from '@reach/router'
import TabsContext from '../../context/tabs'
import TableHeaders from './TableHeaders'

const NewClientOrderDetailInner = ({ orderId, clientId, allowEdit }) => {
  const dialog = useContext(DialogContext)
  const ordersAPI = useContext(OrdersAPIContext)
  const orderAPI = useContext(OrderAPIContext)
  const orderAddAPI = useContext(OrderAddAPIContext)

  const { setCurrentTab } = useContext(TabsContext)
  const { setMessages } = useContext(MessagesContext)

  // Store current pathway for routing
  const location = useLocation()

  // Get option data for items to add
  const {
    getProductLabel,
    options: productOptions,
    isResolved: productsAPIResolved,
  } = useContext(ProductsAPIContext)

  const { options: couponOptions, isResolved: couponsAPIResolved } = useContext(
    CouponsAPIContext
  )
  const {
    getAdjustmentLabel,
    options: adjustmentOptions,
    isResolved: adjustmentsAPIResolved,
  } = useContext(AdjustmentsAPIContext)

  // Hold current state of order
  const [order, setOrder] = useState({
    adjustments: [],
    coupons: [],
    items: [],
  })

  // Destruct order
  const { adjustments, coupons, items } = order

  // Hold Active order data in table
  const [tableData, setTableData] = useState({
    orderAdjustments: [],
    orderCoupons: [],
    orderProducts: [],
  })

  // Validate if we should display Custom Adjustments or Free tests
  const [adjustmentsBox, setAdjustmentsBox] = useState({
    checked: false,
    disabled: false,
  })
  const [couponsBox, setCouponsBox] = useState({
    checked: false,
    disabled: false,
  })

  //Control for user submitting a new order or update
  const [isSubmitting, setIsSubmitting] = useState(false)

  // Don't allow edits if an order was just created
  allowEdit = order.status ? false : allowEdit
  // Allow payments if order status is still in progress
  let allowPayments =
    order.status === 'in_progress' || order.status === 'payment_deferred'
      ? true
      : false

  //Validate if all loading has completed
  let isResolved =
    productsAPIResolved && couponsAPIResolved && adjustmentsAPIResolved

  //Add if order exists
  if (orderId) {
    isResolved = isResolved && orderAPI.isResolved
  }

  // Hold previous orderId for new order messages
  const prevOrderIDRef = useRef()

  //Should we display the add payment button
  var displayAddPayment = Math.floor(order.remaining) <= 0 ? false : true

  // Update page data if order number changed
  useEffect(() => {
    if (
      typeof prevOrderIDRef.current != 'undefined' ||
      orderTitle == 'Add New Order'
    ) {
      // Clear messages between orders
      setMessages([])
    }
    // Set orderId
    prevOrderIDRef.current = orderId

    if (orderId) {
      orderAPI.load(clientId, orderId)
    }
  }, [orderId])

  //Update data once orderAPI is reloaded
  useEffect(() => {
    if (orderAPI.data) {
      setOrder(orderAPI.data)
    }
  }, [orderAPI.data])

  // An new order was created
  useEffect(() => {
    if (orderAddAPI.isResolved) {
      // Reload orders after update to update listed orders for tabs
      ordersAPI.load()
      // Reset isSubmitting
      setIsSubmitting(false)
      // On sucess let user know the order was created
      setMessages([{ text: 'Order has been created.', type: 'status' }])
    }
  }, [orderAddAPI.isResolved])

  // There was an error creating the order
  useEffect(() => {
    if (orderAddAPI.isRejected) {
      //If a specific message is sent store it, otherwise default error message
      var setError = orderAddAPI.error.response.data.errors[0].message
        ? orderAddAPI.error.response.data.errors[0].message
        : 'There was an unexpected error creating the order.'

      // Set error message
      setMessages([{ text: setError, type: 'error' }])
      // Reset response & isSubmitting
      orderAddAPI.reset()
      setIsSubmitting(false)
    }
  }, [orderAddAPI.isRejected])

  // Set title if new or existing order
  var orderTitle = orderId ? `Order ${orderId}` : 'Add New Order'

  // Update Order Title if canceled, completed, or locked
  switch (order.status) {
    case 'canceled':
      orderTitle = `Order ${orderId} - Canceled`
      break

    case 'canceled_non_refundable':
      orderTitle = `Order ${orderId} - Canceled (non-refundable)`
      break

    case 'completed':
      orderTitle = `Order ${orderId} - Completed`
      break

    case 'in_progress':
      orderTitle = `Order ${orderId} - In Progress`
      break

    case 'payment_deferred':
      orderTitle = `Order ${orderId} - Payment deferred`
      break

    default:
      break
  }

  // Set focus to current order
  const orderTab = orderId ? orderId : ''
  setCurrentTab(orderTab)

  // Set loaded order data into table
  useEffect(() => {
    if (items !== undefined && items.length > 0) {
      // Update Product Label based on sku
      // Simplify quantity for display
      let tempProducts = items.map(product => ({
        ...product,
        quantity: parseInt(product.quantity, 10),
        item: getProductLabel(product.sku),
      }))

      // Update Label text for Adjustments
      let tempAdjustments = adjustments.map(adjustment => ({
        ...adjustment,
        label: getAdjustmentLabel(adjustment.type),
      }))

      // Set visual quantity text for Coupons
      let tempCoupons = coupons.map(coupon => ({
        ...coupon,
        quantity: '1',
      }))

      //Set table data to updated values
      setTableData({
        orderAdjustments: tempAdjustments,
        orderCoupons: tempCoupons,
        orderProducts: tempProducts,
      })

      // Set checkbox to true if existing adjustments or coupons data exists
      tempAdjustments.length > 0
        ? setAdjustmentsBox({ checked: true, disabled: true })
        : setAdjustmentsBox({ checked: false, disabled: false })
      tempCoupons.length > 0
        ? setCouponsBox({ checked: true, disabled: true })
        : setCouponsBox({ checked: false, disabled: false })

      // If we can't edit the order, disable checkboxes
      if (allowEdit === false) {
        setAdjustmentsBox({
          checked: tempAdjustments.length > 0 ? true : false,
          disabled: true,
        })
        setCouponsBox({
          checked: tempCoupons.length > 0 ? true : false,
          disabled: true,
        })
      }
    }
  }, [items])

  //Is data done loading?
  if (!isResolved) {
    return <LoadingIndicator />
  }

  // Verify on submit button all data is valid
  function validateOrder(givenData) {
    // At least 1 product exists for the order
    if (givenData.items.length === 0) {
      setMessages([
        { text: 'An order must include at least one product.', type: 'error' },
      ])
      return false
    }
    //Validate that all items quantites & amounts have been set
    var productTable, adjustmentTable, couponTable

    // If we find any errors set to false
    productTable = givenData.items.find(item => item.quantity === '')
      ? false
      : true
    adjustmentTable = givenData.adjustments.find(item => item.amount === '')
      ? false
      : true
    couponTable = givenData.coupons

    var validOrder = productTable && adjustmentTable && couponTable
    // If one was false, let user know and do not allow order to submit
    if (!validOrder) {
      setMessages([
        {
          text: 'Please verify all quantities and amounts are correct.',
          type: 'error',
        },
      ])
      return false
    } else {
      // The order passed validation checks
      return true
    }
  }

  //Order Create Control for API
  function orderCreate() {
    var orderData = {
      items: tableData.orderProducts,
      adjustments: tableData.orderAdjustments,
      coupons: tableData.orderCoupons,
    }
    // Validate order prior to calling orderAddAPI
    if (validateOrder(orderData)) {
      //Disable submit buttons while order is being created
      setIsSubmitting(true)

      // Create new order
      orderAddAPI.load(clientId, orderData)
    }
  }

  // Set the status of the order to in-progress after payment
  function paymentUpdate() {
    // Close open dialog
    dialog.close()
    // Set message
    setMessages([{ text: 'Order payments have been udpated.', type: 'status' }])
    // Was the order previously deferred?
    if (order.status === 'payment_deferred') {
      // Validate order prior to passing data to completion form
      if (validateOrder(order)) {
        //Update order status
        var orderData = { ...order, status: 'in_progress' }
        orderUpdateAPI.load(clientId, orderId, orderData)
      }
    }
  }

  // Popup modal for adding a new payment
  function orderPayment() {
    dialog.openWith({
      content: (
        <PaymentAdd
          clientId={clientId}
          orderId={orderId}
          showHelpers={false}
          allowCustomAmounts={true}
          allowCash={true}
          allowCheck={true}
          onSuccess={() => {
            paymentUpdate(), orderAPI.load(clientId, orderId)
          }}
        />
      ),
    })
  }

  // Remove content from displayed tables, called in OrderEdit
  const handleRemove = (tableId, index) => {
    let specificRows = { ...tableData }
    specificRows[tableId].splice(index, 1)
    setTableData(specificRows)
  }

  // Store updated inline editing for current order
  const handleChange = (updatedData, tableId, index) => {
    let changedData = { ...tableData }
    changedData[tableId][index] = updatedData
    setTableData(changedData)
  }

  //Add new data to tables, called in OrderDetail
  function getOptionData(options, tableId, selected) {
    let specificRows = { ...tableData }
    let newRow
    const addedItem = options.filter(option => option.id === selected.type)
    if (tableId === 'orderProducts') {
      newRow = {
        quantity: '1',
        unit_price: addedItem[0].price,
        sku: addedItem[0].id,
        item: addedItem[0].label,
      }
    } else if (tableId === 'orderAdjustments') {
      // Gift Card or CCTerminal
      newRow = {
        amount: selected.amount,
        type: addedItem[0].id,
        label: addedItem[0].label,
      }
    } else if (tableId === 'orderCoupons') {
      // Free Test Code
      newRow = {
        code: addedItem[0].label,
      }
    }
    specificRows[tableId].push(newRow)
    setTableData(specificRows)
    return
  }

  // Add Select Components for Products & Coupons
  function OrderAddItem({ options, SelectComponent, tableId, buttonLabel }) {
    const [value, setValue] = useState({ type: '' })
    useEffect(() => {
      if (!value.type && options.length > 0) {
        setValue({
          ...value,
          type: options[0].id,
        })
      }
    }, options)

    return (
      <div className="card__actions-inner">
        <SelectComponent
          value={value.type}
          onChange={event => setValue({ ...value, type: event.target.value })}
        />
        <Button
          theme="secondary"
          onClick={() => getOptionData(options, tableId, value)}
        >
          <Icon icon="plus" /> {buttonLabel}
        </Button>
      </div>
    )
  }

  // Prevent negatives & decimals within the inputs
  const handleKeyDown = event => {
    if (
      event.keyCode === 109 ||
      event.keyCode === 189 ||
      event.keyCode === 110 ||
      event.keyCode === 190
    ) {
      event.preventDefault()
    }
  }

  // Add input for Gift Cards
  function OrderAddAdjustment({
    options,
    SelectComponent,
    tableId,
    buttonLabel,
  }) {
    const [value, setValue] = useState({ type: '', amount: '' })

    useEffect(() => {
      if (!value.type && options.length > 0) {
        setValue({
          ...value,
          type: options[0].id,
        })
      }
    }, options)

    return (
      <div className="card__actions-inner rt-td">
        <SelectComponent
          value={value.type}
          onChange={event => setValue({ ...value, type: event.target.value })}
        />
        <div>
          <label className="field-text--label">Amount</label>
          <input
            className="field-text--input"
            min="0.01"
            onChange={event =>
              setValue({ ...value, amount: event.target.value })
            }
            onKeyDown={handleKeyDown}
            required
            step="0.01"
            type="number"
            value={value.amount}
          />
        </div>
        <Button
          theme="secondary"
          disabled={!value.amount}
          onClick={() => getOptionData(options, tableId, value)}
        >
          <Icon icon="plus" /> {buttonLabel}
        </Button>
      </div>
    )
  }

  //If a user created a new order, redirect to that page
  if (orderAddAPI.isResolved && ordersAPI.isResolved) {
    // Was the order created inside root path or within add, remove add from path
    let updatedPath = location.pathname.includes('add')
      ? `${location.pathname.replace('/add', '')}/${orderAddAPI.data.id}`
      : `${location.pathname}/${orderAddAPI.data.id}`
    return (
      // Go to new order either on client-info page or orders page
      <Redirect to={updatedPath} noThrow />
    )
  }

  //Main return for OrderDetail
  return (
    <>
      <Messages />
      <h2 className="header--weighted">{orderTitle}</h2>
      {/* Products */}
      <div className="card--inner">
        <OrderTable
          allowEdit={allowEdit}
          data={tableData.orderProducts}
          handleChange={handleChange}
          handleRemove={handleRemove}
          header={TableHeaders.header.products}
          id={'orderProducts'}
        />
        {allowEdit && (
          <OrderAddItem
            buttonLabel={TableHeaders.header.products.buttonLabel}
            tableId={'orderProducts'}
            options={productOptions}
            SelectComponent={ProductSelect}
          />
        )}
      </div>

      {/* Custom Adjustments */}
      <div className="order--detail">
        <FieldCheckbox
          id={`custom-adjustments`}
          label="Does this order have an adjustment?"
          checked={adjustmentsBox.checked}
          disabled={adjustmentsBox.disabled}
          onChange={() =>
            setAdjustmentsBox({ checked: !adjustmentsBox.checked })
          }
        />
      </div>
      {adjustmentsBox.checked ? (
        <div className="card--inner">
          <OrderTable
            allowEdit={allowEdit}
            data={tableData.orderAdjustments}
            handleChange={handleChange}
            handleRemove={handleRemove}
            header={TableHeaders.header.adjustments}
            id={'orderAdjustments'}
          />
          {allowEdit && (
            <OrderAddAdjustment
              buttonLabel={TableHeaders.header.adjustments.buttonLabel}
              tableId={'orderAdjustments'}
              options={adjustmentOptions}
              SelectComponent={AdjustmentSelect}
            />
          )}
        </div>
      ) : (
        ''
      )}

      {/* Free Test */}
      <div className="order--detail">
        <FieldCheckbox
          id={`test-codes`}
          label={TableHeaders.header.coupons.checkboxLabel}
          checked={couponsBox.checked}
          disabled={couponsBox.disabled}
          onChange={() => setCouponsBox({ checked: !couponsBox.checked })}
        />
      </div>
      {couponsBox.checked ? (
        <div className="card--inner">
          <OrderTable
            allowEdit={allowEdit}
            data={tableData.orderCoupons}
            handleChange={handleChange}
            handleRemove={handleRemove}
            header={TableHeaders.header.coupons}
            id={'orderCoupons'}
          />
          {allowEdit && (
            <OrderAddItem
              buttonLabel={TableHeaders.header.coupons.buttonLabel}
              tableId={'orderCoupons'}
              options={couponOptions}
              SelectComponent={CouponSelect}
            />
          )}
        </div>
      ) : (
        ''
      )}

      {/* Validate if this is not a new order */}
      {orderTitle !== 'Add New Order' ? (
        <div className="card--inner">
          {/* Payments */}
          <h3>Payments</h3>
          <PaymentsAPIProvider clientId={clientId} orderId={orderId}>
            <>
              <PaymentsList updateDisplay={isResolved} />
              {/* If order is cancelled, completed, or locked do not display add payment button */}
              {allowPayments === false ? (
                ''
              ) : (
                <Button
                  theme="secondary"
                  type="submit"
                  onClick={() => orderPayment()}
                >
                  <Icon icon="plus" />
                  <span className="button__label">{'Add New Payment'}</span>
                </Button>
              )}
            </>
          </PaymentsAPIProvider>
        </div>
      ) : (
        // Create New Order
        <div className="card--inner">
          {/* Create Order */}
          <div className="card__actions-inner">
            {allowEdit && (
              <Button
                disabled={isSubmitting}
                theme="primary"
                type="submit"
                onClick={orderCreate}
              >
                {isSubmitting ? (
                  'Please Wait…'
                ) : (
                  <>
                    <span className="button__label">{'Create Order'}</span>
                    <Icon icon="forward" theme="primary" />
                  </>
                )}
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  )
}

const NewClientOrderDetail = props => (
  <OrderAddAPIProvider clientId={props.clientId}>
    <NewClientOrderDetailInner {...props} />
  </OrderAddAPIProvider>
)
export default NewClientOrderDetail
