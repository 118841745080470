
export const tplAppointment = appointment => ({
  appointment,
  date: '',
  slot: '',
  location: {
    id: '',
    name: ''
  },
  completed: false
})

export const tplInfo = () => ({
  contact: {
    name: {
      label: 'Name',
      value: ''
    },
    address: {
      label: 'Street',
      value: ''
    },
    dob: {
      label: 'Birthdate',
      value: ''
    },
    email: {
      label: 'Email',
      value: ''
    },
    phone: {
      label: 'Phone',
      value: ''
    }
  },
  education: {
    completed: {
      label: 'Education Completed',
      value: ''
    },
    school: {
      label: 'Present or Last School',
      value: ''
    },
    dates: {
      label: 'Dates Attended',
      value: ''
    },
    degree: {
      label: 'Degree Earned',
      value: ''
    },
    plans: {
      label: 'Further Education Plans',
      value: ''
    }
  },
  family: [
    {
      label: 'Parent1',
      value: {
        name: {
          label: 'Name',
          value: ''
        },
        occupation: {
          label: 'Occupation',
          value: ''
        },
        work: {
          label: 'Nature of Work',
          value: ''
        },
        education: {
          label: 'Education Completed',
          value: ''
        }
      }
    },
    {
      label: 'Parent2',
      value: {
        name: {
          label: 'Name',
          value: ''
        },
        occupation: {
          label: 'Occupation',
          value: ''
        },
        work: {
          label: 'Nature of Work',
          value: ''
        },
        education: {
          label: 'Education Completed',
          value: ''
        }
      }
    }
  ]
})
