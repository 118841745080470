import React from 'react'

import Aptitude from './aptitude'

const AptitudeGroup = ({ label, divider, aptitudes, values, onChange, style }) => (

  <div className={`aptitude-group ${divider === 'line' ? 'card--divider' : ''}`}>
    {label && <h3>{label}</h3>}
    {aptitudes.map((apt, i) => (<Aptitude
      key={apt.id}
      data={apt}
      value={values[apt.id]}
      onChange={onChange}
      style={style}
    />))}
  </div>
)

export default AptitudeGroup
