/* eslint-disable no-magic-numbers */
import React, { useContext, useEffect } from 'react'

import { Formik, Form, Field } from 'formik'

import Button from '../button'
import FieldTextArea from '../field/textarea'
import ClientUpdateAPIContext, { ClientUpdateAPIProvider } from '../../services/clientUpdate'

const NotesFormInner = ({ id, notesDefault, onSuccess = () => {} }) => {
  const clientUpdateAPI = useContext(ClientUpdateAPIContext);

  useEffect(() => {
    if (clientUpdateAPI.isResolved) {
      onSuccess();
    }
  }, [clientUpdateAPI.isResolved])

  const save = notes => {
    const data = {
      notes
    }

    clientUpdateAPI.load(id, data);
  }

  if (clientUpdateAPI.isResolved) {
    return (<p>Notes Saved!</p>);
  }

  return (
    <Formik
      initialValues={{
        notes: notesDefault
      }}
      enableReinitialize={true}
      validate={values => { }}
      onSubmit={(values, { setSubmitting }) => {
        save(values.notes);
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, isSubmitting }) => (
        <Form>
          <Field
              type={'textarea'}
              name={'notes'}
              children={({field}) => (
                  <FieldTextArea {...field} id={'notes'} label={'Notes'} placeholder={'Add Notes'} />
              )}
          />
          <Button theme={['primary', 'no-icon']} disabled={isSubmitting} type="submit">Save</Button>
        </Form>
      )}
    </Formik>
  )
}

const NotesForm = props => (
  <ClientUpdateAPIProvider {...props}>
    <NotesFormInner {...props} />
  </ClientUpdateAPIProvider>
);

export default NotesForm;
