import React from 'react'

import ToggleOption from './toggleOption'

const Toggle = ({ data, value, onChange }) => (
  <div className={`toggle toggle--${data.style}`}>
    {data.label && <label htmlFor={data.id}>{data.label}</label>}
    {data.options.length > 0
      ? <div className="toggle-options">
          <ToggleOption
            id={data.id}
            value={value}
            style={data.style}
            options={data.options}
            onChange={onChange}
          />
        </div>
      : <div className="toggle-options toggle-options__is-empty">{data.value}</div>
    }
  </div>
)

export default Toggle
