import React, { useContext, useEffect } from 'react'
import Button from '../button'
import DialogContext from '../../context/dialog'
import { Formik, Form } from 'formik'
import OrderDeleteAPIContext from '../../services/orderDelete'
import MessagesContext from '../../context/messages'

export default function OrderCancelForm({
  clientId,
  orderId,
  orderData,
  onSuccess,
  cancelWithoutRefund
}) {
  const dialog = useContext(DialogContext)
  const orderDeleteAPI = useContext(OrderDeleteAPIContext)
  const { setMessages } = useContext(MessagesContext)

  useEffect(() => {
    if (orderDeleteAPI.isResolved) {
      //Close Modal
      dialog.close()
      setMessages([{ text: 'Order has been cancelled.', type: 'status' }])
      onSuccess()
    }
  }, [orderDeleteAPI.isResolved])

  function postCancelOrder() {
    // Validate that the passed order reflects correct client & order
    if (orderData.order === orderId && orderData.user === clientId) {
      orderDeleteAPI.load(clientId, orderId, orderData)
    }
  }

  return (
    <div className="card__actions card--divider">
      <div>
        {cancelWithoutRefund
          ? (
            <>
              <p>Payments have been received for this client.
              Are you sure you want to cancel <b>without giving a refund?</b></p>
            </>
          ) : (
            <p>Are you sure you want to cancel order #{orderId}?</p>
          )
        }
      </div>
      <Formik
        enableReinitialize={true}
        validate={values => {}}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true)
        }}
      >
        {({ values, errors, touched, isSubmitting }) => (
          <Form>
            {/* Cancel Order */}
            <div className="card__actions-inner order-cancel">
              <Button
                theme="primary"
                disabled={isSubmitting}
                type="submit"
                onClick={postCancelOrder}
              >
                {isSubmitting ? 'Please Wait…' : 'Confirm Cancel'}
              </Button>
              {/* Do not cancel order  */}
              <Button
                theme="secondary"
                disabled={isSubmitting}
                type="cancel"
                onClick={() => dialog.close()}
              >
                Go Back
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
