import React, { createRef, useContext, useEffect, useState, useRef } from 'react'
import Select from 'react-select';

import { Formik, Form } from 'formik'
import ComplexTable from '../ComplexTable'
import moment from 'moment-timezone';
import includes from 'lodash/includes';

import ScoresAPIContext, { ScoresAPIProvider } from '../../services/scores';
import ErrorsContext, { ErrorsProvider, ErrorsConsumer } from '../../context/errors';
import { scoresSave } from '../../services/scoresUpdate';
import TasAPIContext from '../../services/tas';
import UserAPIContext from '../../services/user';
import { ExamineeCodesAPIContext, ExamineeCodesAPIProvider } from '../../services/taxonomyTerms';

import FieldDate from '../field/date'
import FieldNumber from '../field/number'
import FieldCheckbox from '../field/checkbox'
import TaSelect from '../field/taSelect'
import Button from '../button'
import { get } from './../../utils/objects';
import LoadingIndicator from '../LoadingIndicator';

const DEBOUNCE_WAIT = 1000;
const DEBOUNCE_OPTIONS = {
  leading: true,
  trailing: false
}

const TestTitle = ({ index, number, name, code, codeOptions, codeForceChoice, onChange, error, refProp }) => (
  <div className="score-field--test" ref={refProp}>
    {number && <span className={'test-title--number'}>{number}</span>}
    {codeOptions
      ? <select
          name={`data[${index}].test.code`}
          onChange={onChange}
          value={code}
          tabIndex={-1}
        >
          {codeForceChoice && <option key={'nullOption'} value={''}>--</option>}
          {codeOptions.map((opt, j) => (
            <option key={j} value={opt}>{opt}</option>
          ))}
        </select>
      : <span className={`test-title--code`}>{code}</span>
    }
    {name && <span className="test-title--name">{name}</span>}
    {error && (<p className="score-field__error">{error.message}</p>)}
  </div>
)

TestTitle.defaultProps = {
  index: 0,
  value: {
    number: '',
    code: '',
    name: '',
    options: {}
  },
  onChange: () => ({})
}

/**
 *
 * @param {*} score
 * @param {*} defaults
 */
const ScoreRow = (score, defaults) => {
  const row = {
    ...score,
    date: score.date || defaults.date,
    inc: score.inc === '1' || score.inc === true,
    nt: score.nt === '1' || score.nt === true,
    nfr: score.nfr === '1' || score.nfr === true,
    admin: get(score, 'admin.id') || defaults.user,
  }

  // JOC-383: Special handling of individual rows
  // Some rows in the score sheet have special meaning and emphasis
  // and the client would like to handle these cases in specific ways.
  switch (get(score, 'test.name')) {
    case 'Graphoria':
      row.test.options.numberErrors.required = true;
      break;
    case 'English Vocabulary':
      row.test.codeOptionsInExtras = true;
    case 'Ideaphoria (flow of ideas)':
      row.test.codeOptionsInExtras = true;
      break;
    case 'Grip Left':
    case 'Grip Right':
      row.test.codeForceChoice = true;
      break;
    default:
      break;
  }

  return row;
};

const getOptionFromRow = row => {
  const options = Object.keys(get(row, 'test.options', {}))
    .filter(key => (key !== 'code' || get(row, 'test.codeOptionsInExtras')));

  return options.length > 0
    ? options[0]
    : null;
};

const TestExtras = ({row, value, index, onChange, onFocus}) => {
  const option = getOptionFromRow(row);

  if (!option) {
    return null;
  }

  const options = get(row, `test.options.${option}`);
  const id = `data[${index}][${option}]`;

  switch (options.type) {
    case 'list_string':
      return (
        <div className={'field-text field-select'}>
          {options.label && <label className="field-text--label" htmlFor={id}>{options.label}</label>}
          <select
            name={id}
            onChange={onChange}
            onFocus={onFocus}
            value={value}
            className="select--minimal"
          >
            {Object.keys(options.options)
              .sort(opt => opt === "" ? -1 : 1)
              .map((opt) => (
                <option key={opt} value={options.options[opt]}>{options.options[opt]}</option>
              ))}
          </select>
        </div>)
    case 'integer':
      return (<FieldNumber
        id={id}
        key={id}
        value={value}
        onChange={onChange}
        label={options.label}
        required={options.required}
        onFocus={onFocus}
      />)
    default:
      if (Array.isArray(options)) {
        return (<div className={'field-text field-select'}>
          <label className="field-text--label" htmlFor={id}>{'Code'}</label>
          <select
            name={`data[${index}].test.[${option}]`}
            onChange={onChange}
            value={value}
            className="select--minimal"
            required={true}
            onFocus={onFocus}
        >
            <option key={'nullOption'} value={''}>--</option>
            {options.map((opt) => (
              <option key={opt} value={opt}>{opt}</option>
            ))}
          </select>
        </div>)
      }
      return null;
  }
}

TestExtras.defaultProps = {
  index: 0,
  value: {
    number: '',
    code: '',
    name: '',
    options: {}
  },
  onChange: () => ({})
}

const TestExtrasAssessor = row => {
  const option = getOptionFromRow(row);

  switch (option) {
    case 'code':
      return row.test['code'];
    default:
      return option
        ? row[option]
        : null;
  }
}

const offset = (el) => {
  var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

const setIncomplete = (norm, row, setFieldValue) => {
  if (norm === 13) {
    setFieldValue(`data[${row}].inc`, true)
  }
}

const getErrors = (test, errors) => {
  if(errors) {
    const error = errors.filter(err => err.test === test);
    return error.length > 0 ? error[0] : null;
  }
}

const firstError = (errors) => {
  if (errors && errors.length > 0) {
    return errors[0].test;
  }

  return null;
}

const scrollToRef = (ref) => ref && window.scrollTo(0, offset(ref.current).top - 20);

const applyDefaults = (data, user) => {
  const DEFAULT_DATE = moment().format('YYYY-MM-DD');
  const DEFAULT_USER = get(user, 'id');
  const DEFAULT_AGE = null;

  const { scores, age, examineeCode } = data;

  if (!scores) {
    return [];
  }

  const output = {
    age: age || DEFAULT_AGE,
    examineeCode: examineeCode,
    data: scores.map(score => ScoreRow(
      score,
      {
        date: DEFAULT_DATE,
        user: DEFAULT_USER
      },
    ))
  };

  return output;
}

const normalizeData = (values, prevData) => {
  if (!values.data) {
    return {};
  }

  return {
    age: values.age,
    id: prevData.id,
    examineeCode: values.examineeCode | null,
    scores: values.data
      // Only post entries with scores
      .filter(score => score != null || score !== '')
      .map(score => {
        return {
          ...score,
          admin: {
            id: score.admin
          },
          score: score.score.toString(),
          norm: score.norm.toString(),
          test: {
            code: score.test.code,
            number: score.test.number,
          }
        }
      })
    };
}

const ScoresAptInner = ({ scores, id }) => {
  const tasAPI = useContext(TasAPIContext);
  const scoresAPI = useContext(ScoresAPIContext);
  const errorsAPI = useContext(ErrorsContext);
  const taxonomyAPI = useContext(ExamineeCodesAPIContext);
  const userAPI = useContext(UserAPIContext);
  const firstErrorRef = useRef(null);

  const [
    activeRow,
    setActiveRow
  ] = useState();

  const [
    messages,
    setMessages
  ] = useState([]);

  // We store data in state so we can update it on initial
  // page load and after successful saves.
  const [
    data,
    setData
  ] = useState({});

  // Ensure the current focused input is visible above the sticky toolbar.
  // Otherwise the last row on the screen is covered when navigating via keyboard.
  const handleFocus = (event) => {
    try {
      const PADDING = 60;
      const inputRect = event.target.getBoundingClientRect();
      const toolbarRect = document.querySelector('.sticky-toolbar').getBoundingClientRect();
      const diff = inputRect.top + inputRect.height + PADDING - toolbarRect.top;
      window.scrollTo({top: window.scrollY + Math.max(0, diff)});
    } catch (error) {
      console.log(error);
    }
  }

  // Load the available TAs.
  useEffect(() => {
    // Load TAs if we haven't yet
    if (tasAPI.isIdle) {
      tasAPI.load();
    }
    if (taxonomyAPI.isIdle) {
      taxonomyAPI.load();
    }
    // Reload scores in case appointments were changed.
    scoresAPI.load(id);
  }, [window.location.pathname])

  // Scroll to first error.
  useEffect(() => {
    // Scroll to first error message
    errorsAPI.clearErrorMessage();
    if (firstError(messages)) {
      errorsAPI.setErrorMessage(firstError(messages));
      errorsAPI.setErrorStatus(true);
      scrollToRef(firstErrorRef);
    }
  }, [messages])

  const { isResolved, isPending, data: scoresData } = scoresAPI;

  // Load score data from the server.
  useEffect(() => {
    let isMounted = true;

    // Scroll to first error message
    if(errorsAPI.errorStatus) {

    }
    if (isResolved && isMounted) {
      setData(scoresAPI.data);
      if (scoresAPI.data.errors.length) {
        errorsAPI.setErrorStatus(true);
      }
    }

    return () => {
      isMounted = false;
    }
  }, [isResolved, scoresData]);

  if (!isResolved || !taxonomyAPI.isResolved) {
    return (<LoadingIndicator />);
  }

  return (
    <Formik
      initialValues={applyDefaults(data, userAPI.data)}
      enableReinitialize={true}
      validate={values => {}}
      onSubmit={(values, { setSubmitting }) => {
        const requestBody = normalizeData(values, data);
        // JOCRF-45
        // Set the body class to indicate we're submitting.
        // This is used to prevent users from navigating to the 
        // summary page before the scores have been saved which
        // may result in reports getting out of sync with scores
        // manifesting as a blank report summary page.
        document.body.classList.add('is-submitting');

        if (requestBody.scores.length <= 0) {
          return;
        }

        scoresSave(id, requestBody)
          .then(response => {
            setMessages(response.data.errors);
            setData(response.data);
            scoresAPI.load(id);
          })
          .catch(error => {
            console.log(error);
            let errors = [];
            if (error.response && error.response.errors) {
              errors = errors.concat(Object.values(error.response.data.errors).map(err => err.title))
            } else if (error.status) {
              errors.push(error.status);
            }

            setMessages(errors.map(err => ({
              type: 'error',
              text: err
            })));
          })
          .finally(() => {
            setSubmitting(false);
            document.body.classList.remove('is-submitting');
          });
        }}
    >
      {({ values, errors, touched, setFieldValue, handleChange, handleBlur, isSubmitting }) => (
        <Form>
          <div className="layout--flow-left">
            <FieldNumber
              id="age"
              name="age"
              value={values.age}
              onChange={handleChange}
              label="Age"
              min="0"
              step={1}
              className="field--age"
              required
            />
            <div className="field-wrapper">
              <label className="field-text--label" htmlFor="examineeCode">Examinee code</label>
              <Select
                className="field-select"
                classNamePrefix="field-select"
                id="examineeCode"
                name="examineeCode"
                options={taxonomyAPI.options}
                value={taxonomyAPI.options.find(option => (
                  option.value === values.examineeCode
                ))}
                onChange={value => {
                  setFieldValue(`examineeCode`, value.value)
                }}
              ></Select>
            </div>
          </div>
          <ComplexTable
            className="-highlight sticky-head"
            pageSize={50}
            getTrGroupProps={(state, rowInfo, column) => {
              return rowInfo
                ? {
                  onFocus(e, handleOriginal) {
                    setActiveRow(rowInfo.index)
                  },
                  className: rowInfo.index === activeRow
                    ? 'row-active'
                    : ''
                }
                : {}
            }}
            data={values.data}
            columns={[
              {
                Header: 'Test',
                accessor: 'test',
                headerClassName: 'rt-header_left',
                Cell: props => (
                  <TestTitle
                    index={props.index}
                    value={props.value}
                    code={props.value.code}
                    name={props.value.name}
                    number={props.value.number}
                    codeOptions={!props.value.codeOptionsInExtras && get(props, 'value.options.code')}
                    codeForceChoice={props.value.codeForceChoice}
                    onChange={handleChange}
                    error={getErrors(props.value.number, messages)}
                    refProp={props.value.number === firstError(messages) ? firstErrorRef : null}
                  />
                )
              },
              {
                Header: 'Score',
                accessor: 'score',
                maxWidth: 100,
                className: 'rt-body_right',
                Cell: props => (
                  <FieldNumber
                    id={`data[${props.index}].score`}
                    value={props.value}
                    onChange={handleChange}
                    onFocus={handleFocus}
                  />)
              },
              {
                Header: '%',
                accessor: 'norm',
                maxWidth: 100,
                className: 'rt-body_right',
                Cell: props => (
                  includes(['norm', 'vss'], get(props, 'row.test.type'))
                    ? (<FieldNumber
                        id={`data[${props.index}].norm`}
                        value={props.value}
                        step={1}
                        onChange={handleChange}
                        onBlur={e => {
                          setIncomplete(props.value, props.index, setFieldValue)
                          handleBlur(e)
                        }}
                        onFocus={handleFocus}
                    />)
                    : null
                  )
              },
              {
                Header: 'Date',
                accessor: 'date',
                maxWidth: 200,
                Cell: props => (<FieldDate tabIndex={-1} id={`data[${props.index}].date`} value={props.value} step={1} onChange={handleChange}
                  onFocus={handleFocus}
                />)
              },
              {
                Header: 'Administrator',
                accessor: 'admin',
                maxWidth: 240,
                Cell: props => (<TaSelect tabIndex={-1} id={`data[${props.index}].admin`} value={props.value} onChange={handleChange} />)
              },
              {
                Header: 'NT',
                accessor: 'nt',
                maxWidth: 60,
                className: 'rt-body_center',
                Cell: props => (<FieldCheckbox tabIndex={-1} id={`data[${props.index}].nt`} name={`data[${props.index}].nt`} checked={props.value} onChange={handleChange} />)
              },
              {
                Header: 'NFR',
                accessor: 'nfr',
                maxWidth: 60,
                className: 'rt-body_center',
                Cell: props => (<FieldCheckbox tabIndex={-1} id={`data[${props.index}].nfr`} name={`data[${props.index}].nfr`} checked={props.value} onChange={handleChange} />)
              },
              {
                Header: 'INC',
                accessor: 'inc',
                maxWidth: 60,
                className: 'rt-body_center',
                Cell: props => (<FieldCheckbox tabIndex={-1} id={`data[${props.index}].inc`} name={`data[${props.index}].inc`} checked={props.value} onChange={handleChange} />)
              },
              {
                Header: '# Tried',
                accessor: 'tried',
                headerClassName: 'rt-header_left',
                className: 'rt-body_center',
                maxWidth: 90,
                Cell: props => (
                props.row.inc
                  ? <FieldNumber id={`data[${props.index}].tried`} value={props.value} onChange={handleChange} autoFocus={true} />
                  : <span>{props.value}</span>
                )
              },
              {
                Header: 'Extra',
                id: 'extras',
                accessor: TestExtrasAssessor,
                maxWidth: 180,
                className: 'rt-body_left',
                Cell: props => (<TestExtras
                  {...props}
                  id={`data[${props.index}][${getOptionFromRow(props.row)}]`}
                  value={props.value}
                  onChange={handleChange}
                  onFocus={handleFocus}
                />)
              },
            ]}
            showPagination={false}
            resizable={false}
            sortable={false}
            minRows={0}
          />

          <div className="card--inner-tight layout--buttons-weight-right sticky-toolbar">
            <Button theme="primary" type="submit" disabled={isSubmitting}>
              <span className="button__label">Save</span>
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const ScoresApt = props => {
  return (
      <ScoresAPIProvider {...props}>
        <ExamineeCodesAPIProvider>
          <ScoresAptInner {...props} />
         </ExamineeCodesAPIProvider>
      </ScoresAPIProvider>
  );
}

export default ScoresApt;
