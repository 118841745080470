/**
 *  API: clientInfo
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request'

const route = id => `/api/clients/${id}/final`;

export const clientInfoFinalLoad = id => () => request(route(id));

// Create a Context
const ClientInfoFinalAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return {};
}

export const ClientInfoFinalAPIProvider = ({ children, id }) => {
  const loader = useLoads('client-info-final', clientInfoFinalLoad, {
    variables: [id],
    defer: false
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  return (
    <ClientInfoFinalAPIContext.Provider value={ Object.assign({}, loader, {
      id,
      data,
    })}>
      {children}
    </ClientInfoFinalAPIContext.Provider>
  )
}

ClientInfoFinalAPIProvider.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.element
}

export default ClientInfoFinalAPIContext
