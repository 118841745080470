import React, { useContext } from 'react'

import Button from '../button'
import Icon from '../icon'
import DialogContext from '../../context/dialog';
import ReportSendForm from './sendReportForm';
import ClientAPIContext from '../../services/client';

const ReportSendButton = ({ id, disabled }) => {
  const dialog = useContext(DialogContext);
  const clientAPI = useContext(ClientAPIContext);

  const heading = 'Send Report';

  const onClick = () => {
    dialog.openWith({
      heading,
      content: <ReportSendForm onCancel={dialog.close} id={id} emails={clientAPI.getContactEmails()} />,
    });
  }

  return (
    <Button theme="secondary" onClick={onClick} disabled={disabled}>
      <span className="button__label">Send Report</span>
      <Icon theme="secondary" icon="arrow" />
    </Button>
  )
}

export default ReportSendButton
