/**
 *  API: Send Report
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request'

const route = id => `/api/clients/${id}/send/report`;

export const reportSend = (id, data) => request(route(id), {
  method: 'POST',
  data
});

// Create a Context
const ReportSendAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return {};
}

export const ReportSendAPIProvider = ({ children }) => {
  const loader = useLoads('report-send', reportSend, {
    defer: true
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  return (
    <ReportSendAPIContext.Provider value={Object.assign({}, loader, {
      data,
    })}>
      {children}
    </ReportSendAPIContext.Provider>
  )
}

ReportSendAPIProvider.propTypes = {
  children: PropTypes.element
}

export default ReportSendAPIContext
