import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { useLoads } from 'react-loads';

import MessagesContext from '../../context/messages';
import { summary, total } from '../../lib/totals'
import { request } from '../../lib/request';

import Button from '../button'
import Codes from './codes'
import ComplexTable from '../ComplexTable';
import FieldNumber from '../field/number'
import FieldCheckbox from '../field/checkbox'
import LoadingIndicator from '../LoadingIndicator';
import Messages from '../messages';

const route = id => `/api/clients/${id}/scores/sds`;

export const sdsLoad = id => () => request(route(id));
export const sdsSave = (id, data) => request(route(id), {
  method: 'post',
  data
});

const ScoresSdsInner = props => {
  const { setMessages, clearMessages } = useContext(MessagesContext);
  // We store data in state so we can update it on initial
  // page load and after successful saves.
  const [
    data,
    setData
  ] = useState({});

  useEffect(() => {
    clearMessages();
  }, []);

  const { isResolved, isPending, response, load } = useLoads('sds-load', sdsLoad, { variables: [props.id]});

  // Load score data from the server.
  useEffect(() => {
    if (isResolved) {
      setData(response.data.sds);
    }
  }, [isResolved, response]);

  if (!isResolved) {
    return (<LoadingIndicator />);
  }
  const calcTotal = (values, index, setFieldValue) => {

    // Making a copy of totals to get an instant summary
    // instead of waiting for the new value from setFieldValue
    const totals = Object.assign({}, values.totals)
    totals[index] = total(values.data, index)

    setFieldValue('totals', totals, false)
    setFieldValue('summary', summary(totals), false)
  }

  const ScoreInput = props => {
    const min = props.index <= 2 ? 0 : 1;
    const max = props.index <= 2 ? 14 : 7;
    return <FieldNumber style="width:100%" {...props} min={min} max={max} width="100" value={props.value} autoFocus={true} onBlur={e => {
      calcTotal(props.values, props.col, props.setFieldValue)
      props.handleBlur(e)
    }} />
  }

  return (
    <Formik
      initialValues={{
        id: response.data.id,
        data: data.data,
        totals: data.totals || { 'R': 0, 'I': 0, 'A': 0, 'S': 0, 'E': 0, 'C': 0 },
        summary: data.summary || { 1: '', 2: '', 3: '' },
        nt: data.nt || false,
        nfr: data.nfr || false,
      }}
      enableReinitialize={true}
      validate={values => { }}
      onSubmit={(values, { setSubmitting }) => {
        setMessages([]);
        sdsSave(props.id, { id: values.id, sds: values })
          .then(response => {
            setData(response.data.sds);
            setMessages([{ text: 'Self-Directed search has been saved.', type: 'status' }])
            load();
            setSubmitting(false);
          })
          .catch(error => {
            let errors = [];
            if (error.response && error.response.errors) {
              errors = errors.concat(Object.values(error.response.data.errors).map(err => err.title))
            } else if (error.status) {
              errors.push(error.status);
            }

//            setMessages(errors.map(err => ({
//              type: 'error',
//              text: err
//            })));
            setSubmitting(false)
          })
      }}
    >
      {({ values, errors, touched, setFieldValue, handleChange, handleBlur, isSubmitting }) => (
        <Form>
          <Messages />
          <div className="sds-status">
            <FieldCheckbox id={`nfr`} label="NFR" checked={values.nfr} onChange={handleChange} />
            <FieldCheckbox id={`nt`} label="Not Taken" checked={values.nt} onChange={handleChange} />
          </div>
          <ComplexTable
            className="react-table--sds-scores"
            data={values.data}
            columns={[
              {
                Header: '',
                accessor: 'label',
                Footer: 'Summary Scores'
              },
              {
                Header: 'R',
                accessor: 'R',
                Cell: props => (
                  <ScoreInput {...props} col="R" id={`data[${props.index}].R`}  onChange={handleChange} values={values} setFieldValue={setFieldValue} handleBlur={handleBlur} />
                ),
                Footer: (
                  <span className="rt-footer">{values.totals.R}</span>
                )
              },
              {
                Header: 'I',
                accessor: 'I',
                Cell: props => (
                  <ScoreInput {...props} col="I" id={`data[${props.index}].I`}  onChange={handleChange} values={values} setFieldValue={setFieldValue} handleBlur={handleBlur} />
                ),
                Footer: (
                  <span className="rt-footer">{values.totals.I}</span>
                )
              },
              {
                Header: 'A',
                accessor: 'A',
                Cell: props => (
                  <ScoreInput {...props} col="A" id={`data[${props.index}].A`}  onChange={handleChange} values={values} setFieldValue={setFieldValue} handleBlur={handleBlur} />
                ),
                Footer: (
                  <span className="rt-footer">{values.totals.A}</span>
                )
              },
              {
                Header: 'S',
                accessor: 'S',
                Cell: props => (
                  <ScoreInput {...props} col="S" id={`data[${props.index}].S`}  onChange={handleChange} values={values} setFieldValue={setFieldValue} handleBlur={handleBlur} />
                ),
                Footer: (
                  <span className="rt-footer">{values.totals.S}</span>
                )
              },
              {
                Header: 'E',
                accessor: 'E',
                Cell: props => (
                  <ScoreInput {...props} col="E" id={`data[${props.index}].E`}  onChange={handleChange} values={values} setFieldValue={setFieldValue} handleBlur={handleBlur} />
                ),
                Footer: (
                  <span className="rt-footer">{values.totals.E}</span>
                )
              },
              {
                Header: 'C',
                accessor: 'C',
                Cell: props => (
                  <ScoreInput {...props} col="C" id={`data[${props.index}].C`}  onChange={handleChange} values={values} setFieldValue={setFieldValue} handleBlur={handleBlur} />
                ),
                Footer: (
                  <span className="rt-footer">{values.totals.C}</span>
                )
              }
            ]}
            defaultPageSize={5}
            showPagination={false}
            resizable={false}
            sortable={false}
          />
          <Codes summary={values.summary} />
          <div className="card--inner card--divider">
            <Button theme="primary" type="submit" disabled={isSubmitting}>
              <span className="button__label">Save</span>
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const ScoresSds = props => {
  return (
    <ScoresSdsInner {...props} />
  );
}

export default ScoresSds
