import React, { Component } from 'react'
import { Router } from "@reach/router"
import { navigate } from 'gatsby'

import ClientInfo from './info'
import Orders from './Orders';
import OrderAdd from '../../order/OrderDetail';
import OrderDefault from '../../order/OrderDefault';
import OrderDetail from '../../order/OrderDetail';
import Summary from './summary'
import Scores from './scores'
import ScoresApt from '../scores-apt'
import ScoresSds from '../scores-sds'
import MenuTab from '../../menuTab'
import Icon from '../../icon'
import PageTitle from '../pageTitle';

import { tplAppointment, tplInfo } from '../../../utils/template'

import { appointments } from '../../../store/mock-calendar'
import { info } from '../../../store/mock-calendar'

import { scores, sds, aptitudes, mockResults } from '../../../store/mock-scores'


/**
 * @todo obviously get this some other way
 *
 * @param {int} id
 *   Client id
 * @param {object} info
 */
const mockClient = (id, info) => {
  const contact = Object.keys(info.contact).map(i => info.contact[i].value)
  return {
    id,
    firstname: contact[0].split(' ')[0],
    lastname: contact[0].split(' ')[1],
    email: contact[3],
    phone: contact[4],
  }
}

const clientRedirect = id => `/client/${id}`

class ClientDetail extends Component {

  constructor() {
    super()

    this.state = {
      appointments: [tplAppointment(1), tplAppointment(2), tplAppointment(3)],
      info: tplInfo(),
      summary: [],
      primary: [],
      emphasis: [],
      discussion: [],
      aptitudes: []
    }
  }

  componentDidMount() {
    this.fetchAptitudes()
  }

  fetchInfo = () => {
    this.setState({ info })
  }

  fetchAppointments = () => {
    this.setState({ appointments })
  }

  addAppointment = appointment => {
    const { id } = this.props
    const { info } = this.state

    // @todo obviously get this some other way
    const client = mockClient(id, info)
    const redirect = clientRedirect(id)

    navigate(`/client-add-appt${appointment}/`, { state: { client, redirect } })
  }

  completeAppointment = (id, status) => {

    const { appointments } = this.state

    appointments.forEach(appointment => {
      if (appointment.id === id) {
        appointment.completed = status
      }
    })

    this.setState({ appointments })
  }

  rescheduleAppointment = appointment => {

    const {id} = this.props
    const {info} = this.state
    const client = mockClient(id, info)
    const redirect = clientRedirect(id)

    navigate(`/client-add-appt${appointment}/`, { state: { client, redirect } })
  }

  fetchResults = results => {
    const { summary, primary, emphasis, discussion } = mockResults(results)
    console.log('fetched results', { summary, primary, emphasis, discussion })
    this.setState({ summary, primary, emphasis, discussion })
  }

  fetchAptitudes = () => {

    // request aptitudes from api
    this.setState({ aptitudes })
  }

  render() {
    const { id } = this.props
    const { appointments, info, aptitudes } = this.state

    const pages = [
      {
        title: 'Information',
        path: `/client/${id}`,
        icon: 'client',
        partial: 0
      },
      {
        title: 'Test Scores',
        path: `/client/${id}/scores`,
        icon: 'research',
        partial: 1
      },
      {
        title: 'Summary',
        path: `/client/${id}/summary`,
        icon: 'summary',
        partial: 0,
      },
      {
        title: 'Orders',
        path: `/client/${id}/orders`,
        icon: 'accounting',
        partial: 1
      }
    ]

    return (
      <>
        <PageTitle />
        <div>
          <nav className="menu-tabs">
            <ul>
              {pages.map((page, i) => (
                <li key={i}>
                  <MenuTab partial={page.partial} to={page.path}>
                    <Icon icon={page.icon} theme="default" />
                    {page.title}
                  </MenuTab>
                </li>
              ))}
            </ul>
          </nav>
          <Router>
            <ClientInfo
              path={pages[0].path}
              id={id}
              info={info}
              fetchInfo={this.fetchInfo}
              appointments={appointments}
              addAppointment={this.addAppointment}
              fetchAppointments={this.fetchAppointments}
              completeAppointment={this.completeAppointment}
              rescheduleAppointment={this.rescheduleAppointment}
            />
            <Scores path={pages[1].path} clientId={id}>
              <ScoresApt path="/" scores={scores} id={id} />
              <ScoresSds path="sds" sds={sds} id={id} />
            </Scores>
            <Summary
              path={pages[2].path}
              aptitudes={this.state.aptitudes}
              summary={this.state.summary}
              primary={this.state.primary}
              emphasis={this.state.emphasis}
              discussion={this.state.discussion}
              id={id}
              fetch={this.fetchResults}
            />
            <Orders path={pages[3].path} id={id} allowEdit={true}>
              <OrderAdd path="add" clientId={id} allowEdit={true}/>
              <OrderDetail path=":orderId" clientId={id} allowEdit={true}/>
              <OrderDefault path="/" id={id} />
            </Orders>
          </Router>
        </div>
      </>
    )
  }
}

export default ClientDetail
