import React, { useContext, useEffect } from 'react'
import Button from '../button'
import DialogContext from '../../context/dialog'
import { Formik, Form } from 'formik'
import OrderUpdateAPIContext from '../../services/orderUpdate'
import MessagesContext from '../../context/messages'

export default function OrderCancelForm({
  clientId,
  orderId,
  orderData,
  orderStatus,
  onSuccess,
}) {
  const dialog = useContext(DialogContext)
  const orderUpdateAPI = useContext(OrderUpdateAPIContext)
  const { setMessages } = useContext(MessagesContext)

  // An order was successfully updated
  useEffect(() => {
    if (orderUpdateAPI.isResolved) {
      //Close Modal
      dialog.close()
      // Update message based on status
      var displayMessage = orderStatus === 'completed' ? 'Order has been marked as completed.' : 'Order has been re-opened.'
      setMessages([
        { text: displayMessage, type: 'status' },
      ])
      onSuccess()
    }
  }, [orderUpdateAPI.isResolved])

  function postCompleteOrder() {
      orderUpdateAPI.load(clientId, orderId, orderData)
  }

  return (
    <div className="card__actions card--divider">
      <div>
        {orderStatus !== 'completed' ? (
          <p>Are you sure you want to re-open this order #{orderId}?</p>
        ) : (
          <p>Are you sure you want to mark this order #{orderId} complete?</p>
        )}
      </div>
      <Formik
        enableReinitialize={true}
        validate={values => {}}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true)
        }}
      >
        {({ values, errors, touched, isSubmitting }) => (
          <Form>
            {/* Complete Order */}
            <div className="card__actions-inner order-complete">
              <Button
                theme="primary"
                disabled={isSubmitting}
                type="submit"
                onClick={postCompleteOrder}
              >
                {isSubmitting ? (
                  'Please Wait…'
                ) : (
                  <>
                    {orderStatus !== 'completed'
                      ? 'Confirm Re-open'
                      : 'Confirm Complete'}
                  </>
                )}
              </Button>
              {/* Do not complete order  */}
              <Button
                theme="secondary"
                disabled={isSubmitting}
                type="submit"
                onClick={() => dialog.close()}
              >
                Go Back
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
