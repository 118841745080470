import React, { useContext, useEffect } from 'react'
import { Router } from "@reach/router"

import ClientAPIContext from '../../services/client';

import AddAppt from './pages/edit/appt';
import ClientDetail from './pages'
import Complete from './pages/edit/complete';
import Payment from './pages/edit/payment';

const Client = ({ id }) => {
  const clientAPI = useContext(ClientAPIContext);

  // Refresh data when the url updates.
  useEffect(() => {
    if (!clientAPI.isIdle) {
      clientAPI.load();
    }
  }, [id])

  return (
    <Router>
      <AddAppt path={'/client/:client/edit/appt'} number={'1'} />
      <AddAppt path={'/client/:client/edit/appt/:number'}/>
      {/* Allow for mutiple possible routes */}
      <Payment path={'/client/:client/edit/payment/:orderId'}/>
      <Payment path={'/client/:client/edit/payment/add'}/>
      <Payment path={'/client/:client/edit/payment'}/>
      <Complete path={'/client/:client/edit/complete'}/>
      <ClientDetail default id={id}/>
    </Router>
  )
}

export default Client
